import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { NotificationService } from './notification.service';
import { SharedService } from '../shared.service';
import {SubSink} from "subsink";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  rtl = 'rtl';
  private subs = new SubSink();
  constructor(
    private sharedService: SharedService,
    private renderer: Renderer2,
    public notificationService: NotificationService
  ) {
    this.subs.add(this.sharedService.siteDirection.subscribe(direction => {
      this.rtl = direction;
    }));
  }

  ngOnInit(): void {
    this.notificationService.visibility.subscribe(visibility => {
      const element = document.getElementById('notification');
      if (visibility) {
        this.renderer.removeClass(element, 'invisible');
        this.renderer.addClass(element, 'visible');
      } else {
        this.renderer.removeClass(element, 'visible');
        this.renderer.addClass(element, 'invisible');
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
