import {Component, OnDestroy, OnInit} from '@angular/core';
import { SharedService } from '../shared.service';
import { ProductRFQ } from '../../product/models/product.rfq.model';
import { Router } from '@angular/router';
import {SubSink} from "subsink";

@Component({
  selector: 'app-rfq-basket',
  templateUrl: './rfq-basket.component.html',
  styleUrls: ['./rfq-basket.component.sass'],
})
export class RfqBasketComponent implements OnInit, OnDestroy {
  products: ProductRFQ[] = [];
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  slider = document.querySelector<HTMLElement>('.fixed-height');
  rfqTitles: any;
  private subs = new SubSink();
  constructor(private sharedService: SharedService, private router: Router) {
    this.subs.add(this.sharedService.rfqBasketProducts.subscribe(products => {
      this.products = products;
    }));
    this.subs.add(this.sharedService.rfqTitles.subscribe(titles => {
      this.rfqTitles = titles;
    }));
  }

  ngOnInit(): void {
  }

  startDragging(e: any, flag: any, el: any) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }
  stopDragging(e: any, flag: any) {
    this.mouseDown = false;
  }
  moveEvent(e: any, el: any) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }
  deleteProductFromBasket(index: number): void {
    this.sharedService.removeProductFromRFQBasket(index);
    if (this.products.length === 0 && this.router.url.split('/')[3] && this.router.url.split('/')[3] === 'contact-supplier') {
      window.history.back();
    }
  }
  clearAll(): void {
    if (this.router.url.split('/')[3] && this.router.url.split('/')[3] === 'contact-supplier') {
      window.history.back();
    }
    this.sharedService.removeAllProductsFromRFQBasket();
  }

  goToContactSupplier(): void {
    this.router.navigate(['/', this.sharedService.language, 'rfq', 'contact-supplier']).then(() => {});
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
