<div class="px-2 d-flex flex-column">
  <label *ngIf="title" class="medium-gray fs-16" [for]="id">{{ title }} <span *ngIf="required" class="red">*</span> </label>
  <input
    [dir]="dir !== '' ? dir : direction"
    [type]="type"
    [maxlength]="maxLength"
    [formControl]="control"
    class="form-control mt-1"
    autocomplete="new-password"
    [placeholder]="placeholder"
    [id]="id"
    [name]="id"
    [pattern]="pattern"
  />
</div>
