<div
  class="quick-login shadow-sm"
  [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
  [dir]="direction === 'rtl' ? 'rtl' : 'ltr'"
>
  <form class="container-fluid" autocomplete="off">
    <div class="row">
      <div class="col-12">{{ loginTitles.login }}</div>
      <div class="col-12">
        <app-form-field
          [id]="'quick-login-email'"
          [dir]="'ltr'"
          [control]="emailController"
          [title]="loginTitles.email"
          [placeholder]="loginTitles.email"
        ></app-form-field>
      </div>
      <div class="col-12">
        <app-form-password-filed
          [id]="'quick-login-password'"
          [dir]="'ltr'"
          [control]="passwordController"
          type="password"
          [title]="loginTitles.password"
          [placeholder]="loginTitles.password"
        ></app-form-password-filed>
      </div>
      <div class="col-12 form-group mt-3">
        <app-form-checkbox class="mt-3" [title]="loginTitles.remember" [control]="rememberMe"></app-form-checkbox>
      </div>
      <div class="col-12">
        <div class="forget">
          <a class="fs-14 mt-3" [routerLink]="['/' + language, 'account', 'forgetPassword']">{{
            loginTitles.forget
          }}</a>
        </div>
        <span class="pointer fs-16 blue bold mt-3 mb-1" *ngIf="showResendEmail" (click)="sendEmail()">{{
          loginTitles.request_confirm_email
        }}</span>
        <button class="btn btn-primary w-100" (click)="login()">{{ loginTitles.login }}</button>
      </div>
    </div>
  </form>
</div>
