import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {SharedService} from "../shared.service";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.sass']
})
export class NotFoundComponent {

  language = 'fa';
  apiTitles: any;
  constructor(private router: Router, private sharedService: SharedService) {
    this.sharedService.currentLanguage.subscribe(lang => {
      this.language = lang;
    });
    this.sharedService.apiTitles.subscribe(titles => {
      this.apiTitles = titles;
    });
  }

  goToHome() {
    this.router.navigate(['/', this.language]);
  }

}
