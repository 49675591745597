import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '../shared.service';
import {CategoryService} from '../../category/category.service';
import {Category} from '../../category/category.model';
import {AccountService} from '../../account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import Swal from 'sweetalert2';
import {Language} from "../language/language.model";
import {SubSink} from "subsink";
import { forkJoin} from "rxjs";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass'],
})
export class MenuComponent implements OnInit, OnDestroy {
  menuTitles: any;
  headerTitles: any;
  apiTitles: any;
  categoriesMenuTitleBehavioral = this.sharedService.categoriesMenuTitles;
  categoriesMenuTitle: any;
  direction = 'rtl';
  language = this.sharedService.language;
  siteLanguages: Language[] = [];
  currentLanguage = 'فارسی';
  categories: Category[] = [];
  isVendor = false;
  isTax = false;
  isCustomer = false;
  textFa = 'درحال ساخت';
  textEn = 'Under Construction';
  textAr = 'تحت التشيد';
  textFr = 'En construction';
  disableCategoryHover = false;
  private subs = new SubSink();

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private categoryService: CategoryService,
    private accountService: AccountService,
    public activatedRoute: ActivatedRoute
  ) {
    this.subs.add(this.sharedService.languages.subscribe(languages => {
      this.siteLanguages = languages;
      this.currentLanguage = this.siteLanguages.find(lang => lang.title === this.language)!.name;
    }));
    this.subs.add(this.accountService.isVendor.subscribe(isVendor => {
      this.isVendor = isVendor;
    }));
    this.subs.add(this.accountService.isTax.subscribe(isTax => {
      this.isTax = isTax;
    }));
    this.subs.add(this.accountService.isCustomer.subscribe(isCustomer => {
      this.isCustomer = isCustomer;
    }));
    this.subs.add(this.sharedService.apiTitles.subscribe((titles) => {
      this.apiTitles = titles;
    }));
  }

  ngOnInit(): void {
    this.subs.add(this.categoryService.categoriesBehavioral.subscribe(categories => {
      this.categories = categories;
    }));
    this.subs.add(this.sharedService.siteDirection.subscribe(direction => {
      this.direction = direction!;
    }));
    this.subs.add(this.sharedService.currentLanguage.subscribe(language => {
      this.language = language;
      this.currentLanguage = this.sharedService.siteLanguages.find(lang => lang.title === this.language)!.name;
      this.sharedService.onLanguageChanges(language!, 'menuTitle');
      this.sharedService.onLanguageChanges(language, 'headerTitle');
      this.sharedService.onLanguageChanges(language!, 'categoriesMenuTitle');
    }));
    this.subs.add(this.sharedService.menuTitles.subscribe(titles => {
      this.menuTitles = titles;
    }));
    this.subs.add(this.sharedService.headerTitles.subscribe(titles => {
      this.headerTitles = titles;
    }));
    this.subs.add(this.categoriesMenuTitleBehavioral.subscribe(titles => {
      this.categoriesMenuTitle = titles;
    }));
  }

  onLanguageChange(): void {
    document.getElementById('language-dropdown')!.blur();
    this.sharedService.setLanguage(
      this.sharedService.siteLanguages.find(lang => lang.name === this.currentLanguage)!.title
    );
  }

  RFQ(): void {
    this.router.navigate(['/'], {fragment: 'rfq'}).then(() => {
      const element = document.querySelector('#rfq');
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
      }
    });
  }

  goToVendor(): void {
    window.open(
      environment.vendorUrl +
      '?token=' +
      encodeURIComponent(localStorage.getItem('token')!) +
      '&name=' +
      localStorage.getItem('name') +
      '&language=' +
      this.sharedService.language,
      '_blank'
    );
  }

  goToCustomer(): void {
    window.open(
      environment.customerUrl +
      '?token=' +
      encodeURIComponent(localStorage.getItem('token')!) +
      '&name=' +
      localStorage.getItem('name') +
      '&language=' +
      this.sharedService.language,
      '_blank'
    );
  }

  showUnderConstruction(): void {
    let text = '';
    switch (this.language) {
      case 'fa': {
        text = this.textFa;
        break;
      }
      case 'en': {
        text = this.textEn;
        break;
      }
      case 'ar': {
        text = this.textAr;
        break;
      }
      case 'fr': {
        text = this.textFr;
        break;
      }
    }
    Swal.fire({
      icon: 'warning',
      text: text,
      confirmButtonText: this.apiTitles.confirm_button,
    });
  }

  goToRFQ(): void {
    if (this.accountService.isAuthenticated.value) {
      this.router.navigate(['/', this.language, 'rfq']);
    } else {
      Swal.fire({
        icon: "warning",
        confirmButtonText: this.apiTitles.confirm_button,
        text: this.apiTitles.login
      }).then(result => {
        if (result.isConfirmed) {
          this.router.navigate(['/', this.language, 'account', 'login'], {
            queryParams: {
              url: 'rfq'
            }
          })
        }
      })
    }
  }

  goToProductSearch(category: Category) {
    this.disableCategoryHover = true;
    this.router.navigate([this.language, 'products'], {
      queryParams: {
        ...this.activatedRoute.snapshot.queryParams,
        categoryId: category.Id,
        categoryFilter: true,
        page: '1'
      }
    });
  }

  // getQueryParams(category: Category) {
  //   return {
  //     ...this.activatedRoute.snapshot.queryParams,
  //     categoryId: category.Id,
  //     categoryFilter: true,
  //     page: '1'
  //   }
  // }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
