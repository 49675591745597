import {Component, OnDestroy, OnInit} from '@angular/core';
import { SharedService } from '../shared.service';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {SubSink} from "subsink";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent implements OnInit, OnDestroy {
  footerTitle: any;
  direction = 'rtl';
  language = 'fa';
  facebookIcon = faFacebookF;
  instagramIcon = faInstagram;
  linkedInIcon = faLinkedin;
  envelopeIcon = faEnvelope;
  private subs = new SubSink();
  constructor(private sharedService: SharedService) {
    this.subs.add(this.sharedService.currentLanguage.subscribe(language => {
      this.language = language;
      this.sharedService.onLanguageChanges(language, 'footerTitle');
    }));
    this.subs.add(this.sharedService.footerTitles.subscribe(titles => {
      this.footerTitle = titles;
    }));
    this.subs.add(this.sharedService.siteDirection.subscribe(direction => {
      this.direction = direction;
    }));
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
