import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ProductSearch } from '../product-search.model';
import { SharedService } from '../../shared/shared.service';
import { ProductService } from '../product.service';
import {SubSink} from "subsink";

@Component({
  selector: 'app-product-card-search',
  templateUrl: './product-card-search.component.html',
  styleUrls: ['./product-card-search.component.sass'],
})
export class ProductCardSearchComponent implements OnInit, OnDestroy {
  @Input() product!: ProductSearch;
  direction = 'rtl';
  sliderProductTitles: any;
  language = 'fa';
  private subs = new SubSink();
  constructor(private sharedService: SharedService, private productService: ProductService) {
    this.subs.add(this.sharedService.currentLanguage.subscribe(language => {
      this.language = language;
    }));
  }

  ngOnInit(): void {
    this.subs.add(this.sharedService.siteDirection.subscribe(direction => {
      this.direction = direction;
    }));
    this.subs.add(this.sharedService.sliderProductTitles.subscribe(titles => {
      this.sliderProductTitles = titles;
    }));
  }
  onSelect(): void {
    this.productService.productClearBehavioral.next(true);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
