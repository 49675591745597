import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-password-filed',
  templateUrl: './form-password-filed.component.html',
  styleUrls: ['./form-password-filed.component.sass'],
})
export class FormPasswordFiledComponent {
  @Input() title: string = '';
  @Input() control = new FormControl();
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() maxLength: number = 2500;
  @Input() id: string = 'title';
  @Input() dir = 'ltr';
  @Input() required = false;
  constructor() {}

  changeType(): void {
    switch (this.type) {
      case 'text': {
        this.type = 'password';
        break;
      }
      case 'password': {
        this.type = 'text';
        break;
      }
    }
  }
}
