<div class="d-flex w-100 align-items-start">
  <div class="d-flex align-items-center w-100">
    <ul class="submenu-list w-100">
      <div
        class="py-2 submenu-link text-truncate pointer"
        (click)="goToProductSearch(category)"
      >
        {{ category.currentName }}
      </div>
      <li *ngFor="let child of category.Childs; let i = index" class="py-2 d-flex flex-column w-100">
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="submenu-link text-truncate pointer"
            (click)="goToProductSearch(child)"
          >
            {{ child.currentName }}
          </div>
<!--          <span class="pointer" (click)="onOpenAccordion(i)">-->
<!--            <img src="../../../../assets/icons/cherven-down.svg" alt="" *ngIf="i !== openedItemIndex" />-->
<!--            <img src="../../../../assets/icons/cherven-up.svg" alt="" *ngIf="i === openedItemIndex" />-->
<!--          </span>-->
        </div>
<!--        <ul class="second-submenu-list w-100" *ngIf="openedItemIndex === i">-->
<!--          <li class="second-submenu-item" *ngFor="let secondSubCategory of child.Childs">-->
<!--            <div-->
<!--              class="second-submenu-link pointer text-truncate"-->
<!--              [routerLink]="[this.language, 'products']"-->
<!--              [queryParams]="{ categoryId: secondSubCategory.Id }"-->
<!--            >-->
<!--              {{ secondSubCategory.Name }}-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
      </li>
    </ul>
  </div>
</div>
