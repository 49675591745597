import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { VendorProfile } from '../vendor.model';
import { SharedService } from '../../shared/shared.service';
import { VendorService } from '../vendor.service';
import {SubSink} from "subsink";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-vendor-card-search',
  templateUrl: './vendor-card-search.component.html',
  styleUrls: ['./vendor-card-search.component.sass'],
})
export class VendorCardSearchComponent implements OnInit, OnDestroy {
  @Input() vendor!: VendorProfile;
  language = 'fa';
  defaultProfile = '../../../assets/images/vendor-profile.png';
  vendorProfileTitles: any;
  logo: any;
  logoPath = '';
  private subs = new SubSink();
  constructor(private sharedService: SharedService, private vendorService: VendorService) {
    this.subs.add(this.sharedService.currentLanguage.subscribe(language => {
      this.language = language;
    }));
    this.subs.add(this.sharedService.vendorProfileTitles.subscribe(titles => {
      this.vendorProfileTitles = titles;
    }));
  }

  ngOnInit(): void {
    this.logo = this.vendor.Pictures.find(p => +p.Type === 1);
    this.logoPath = '';
    this.logoPath = this.logo.Path;
    // this.logoPath = `${environment.apiUrl}${this.logo.Path}`;
  }

  onSelect(): void {
    this.vendorService.vendorClear.next(true);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
