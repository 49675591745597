import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Category } from './category.model';
import { SharedService } from '../shared/shared.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  //##region Api Urls
  baseCategoriesUrl: string = '/api/v1/WebCategory/GetcategoryList';
  //##endregion

  //##region Streams
  categoriesBehavioral = new BehaviorSubject<Category[]>([]);
  //##endregion

  //##region Methods
  getCategories(): void {
    const value = JSON.stringify({ language: this.sharedService.language });
    this.httpClient
      .get(this.baseCategoriesUrl, {
        headers: {
          value: value,
        },
      })
      .subscribe((response: any) => {
        const categories = [];
        for (let item of response.jsonResult.Data.category) {
          const category: Category = item;
          category.currentName = category.Name[this.sharedService.language]
            ? category.Name[this.sharedService.language]
            : category.Name;
          for (let i = 1; i < category.PicsPath.length; i++) {
            category.PicsPath[i] = environment.apiUrl + category.PicsPath[i];
          }
          for (let subItem of category.Childs) {
            const child: Category = subItem;
            child.currentName = child.Name[this.sharedService.language]
              ? child.Name[this.sharedService.language]
              : child.Name;
            if (child.PicsPath) {
              for (let i = 1; i < child.PicsPath.length; i++) {
                child.PicsPath[i] = environment.apiUrl + child.PicsPath[i];
              }
            }
          }
          categories.push(category);
        }
        this.categoriesBehavioral.next(categories);
      });
  }
}
