import {Component, OnInit, Renderer2} from '@angular/core';
import { SharedService } from './shared/shared.service';
import {Router, NavigationStart, Event as NavigationEvent, NavigationEnd, ActivatedRoute} from '@angular/router';
import { AccountService } from './account/account.service';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ProductRFQ } from './product/models/product.rfq.model';
import { CategoryService } from './category/category.service';
import {ProductService} from "./product/product.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  rfqBasketProducts: ProductRFQ[] = [];
  direction = 'rtl';
  currentUrl = '';
  currentLanguage = 'fa';
  chooseLanguageFa = {
    title: 'انتخاب زبان',
    text: 'ما تشخیص دادیم که زبان شما با زبان صفحه فعلی متفاوت است،آیا می خواهید زبان صفحه را تغییر دهید؟',
    confirm: 'بله، زبان را تغییر بده',
    cancel: 'نه، با همین زبان ادامه بده',
  };
  chooseLanguageEn = {
    title: 'Choose Language',
    text: 'We detect your language is different from the current page language, do you want to change the page language?',
    confirm: 'Yes, change the language',
    cancel: 'No, continue with the current language',
  };
  chooseLanguageAr = {
    title: 'اختر اللغة',
    text: 'اكتشفنا اختلاف لغتك عن لغة الصفحة الحالية، هل تريد تغيير لغة الصفحة؟',
    confirm: 'نعم، قم بتغيير اللغة',
    cancel: 'لا، استمر في اللغة الحالية',
  };
  chooseLanguageFr = {
    title: 'Choisissez la langue',
    text: 'Nous détectons que votre langue est différente de la langue de la page actuelle, voulez-vous changer la langue de la page?',
    confirm: 'Oui, changer la langue',
    cancel: 'Non, continuer avec la langue actuelle',
  };
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private accountService: AccountService,
    private titleService: Title,
    private location: Location,
    private renderer: Renderer2,
    private categoryService: CategoryService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute
  ) {
    this.sharedService.getLanguages();
    this.sharedService.getIPtoLanguage().subscribe(response => {
      this.sharedService.setLanguage(response.jsonResult.Data.SiteLanguage);
    }, () => {
      this.sharedService.setLanguage('en');
    }, () => {})
    this.sharedService.siteDirection.subscribe(direction => {
      this.direction = direction;
      this.renderer.setAttribute(document.body, 'dir', direction);
    });
    this.sharedService.rfqBasketProducts.subscribe(products => {
      this.rfqBasketProducts = products;
    });
    if (localStorage.getItem('email')) {
      this.accountService.isEmailConfirmed(localStorage.getItem('email')!);
    }
    this.sharedService.currentLanguage.subscribe(language => {
      this.productService.getProductSearchFilters();
      this.productService.getBrands();
      switch (language) {
        case 'fa': {
          this.titleService.setTitle('پلتفرم هوشمند صادرات و بازاریابی فرامرزی');
          break;
        }
        case 'en': {
          this.titleService.setTitle('Intelligent Cross-Border Export and Marketing Platform');
          break;
        }
        case 'ar': {
          this.titleService.setTitle('منصة تصدير وتسويق عبر الحدود الذكية');
          break;
        }
        case 'fr': {
          this.titleService.setTitle('Plateforme intelligente d\'exportation et de marketing transfrontalier');
          break;
        }
        case 'de': {
          this.titleService.setTitle('Intelligente plattformübergreifende Export- und Marketingplattform');
          break;
        }
        case 'zh': {
          this.titleService.setTitle('智能跨境出口和营销平台');
          break;
        }
        case 'ru': {
          this.titleService.setTitle('Интеллектуальная платформа экспорта и маркетинга за границей');
          break;
        }
        case 'hi': {
          this.titleService.setTitle('स्मार्ट क्रॉस-बॉर्डर निर्यात और मार्केटिंग प्लेटफॉर्म।');
          break;
        }
        case 'pt': {
          this.titleService.setTitle('Plataforma inteligente de exportação e marketing transfronteiriço');
          break;
        }
        case 'es': {
          this.titleService.setTitle('Plataforma inteligente de comercialización y exportación transfronteriza');
          break;
        }
        case 'sw': {
          this.titleService.setTitle('Intelligent gränsöverskridande export- och marknadsföringsplattform');
          break;
        }
        case 'th': {
          this.titleService.setTitle('แพลตฟอร์มการส่งออกและการตลาดข้ามพรมแดนอัจฉริยะ');
          break;
        }
        case 'tr': {
          this.titleService.setTitle('Akıllı Sınır Ötesi İhracat ve Pazarlama Platformu');
          break;
        }
        case 'ur': {
          this.titleService.setTitle('انٹیلجنٹ کراس بارڈر ایکسپورٹ اور مارکیٹنگ پلیٹ فارم');
          break;
        }
        case 'af': {
          this.titleService.setTitle('Intelligente grensoverschrijdende uitvoer- en bemarkingsplatform');
          break;
        }
        case 'hy': {
          this.titleService.setTitle('Խելացի միջսահմանային արտահանման և շուկայավարման հարթակ');
          break;
        }
        case 'he': {
          this.titleService.setTitle('פלטפורמת יצוא ושיווק חכמה חוצה גבולות');
          break;
        }
        case 'id': {
          this.titleService.setTitle('Platform Ekspor dan Pemasaran Lintas Batas yang Cerdas');
          break;
        }
        case 'ja': {
          this.titleService.setTitle('インテリジェントな国境を越えた輸出およびマーケティング プラットフォーム');
          break;
        }
        case 'ms': {
          this.titleService.setTitle('Platform Eksport dan Pemasaran Rentas Sempadan Pintar');
          break;
        }
        default : {
          this.titleService.setTitle('Intelligent Cross-Border Export and Marketing Platform');
          break;
        }
      }
      setTimeout(() => {
        this.sharedService.onLanguageChanges(language, 'loginTitle');
        this.sharedService.onLanguageChanges(language, 'apiTitle');
        this.sharedService.onLanguageChanges(language, 'alertTitle');
        this.sharedService.onLanguageChanges(language, 'breadcrumbTitle');
        this.sharedService.onLanguageChanges(language, 'dayOfferTitle');
        this.sharedService.onLanguageChanges(language, 'vendorProfileTitle');
        this.sharedService.onLanguageChanges(language, 'rfqTitle');
        this.sharedService.onLanguageChanges(language, 'publicTitle');
      }, 50);
      this.sharedService.getUnits();
      this.categoryService.getCategories();
      const urlSegments = this.currentUrl.split('/');
      if (urlSegments.length > 1) {
        urlSegments[1] = language;
        this.location.replaceState(urlSegments.join('/'));
      }
    });
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;
        if (event.url.includes('login') && this.accountService.isAuthenticated.value) {
          this.router.navigate(['/']);
        }
      }
      if (event instanceof NavigationEnd) {
        // if (this.activatedRoute.snapshot.queryParams['token'] !== undefined) {
        //   localStorage.setItem('token', decodeURIComponent(this.activatedRoute.snapshot.queryParams['token']));
        //   localStorage.setItem('name', this.activatedRoute.snapshot.queryParams['name']);
        //   localStorage.setItem('language', this.activatedRoute.snapshot.queryParams['language'] ?? 'fa');
        //   this.accountService.isAuthenticated.next(true);
        //   this.router.navigate([this.sharedService.language]);
        // } else if (window.location.pathname === '/') {
        //   this.router.navigate([this.sharedService.language]);
        // }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }

  ngOnInit(): void {
    this.accountService.isAuthenticated.subscribe(isAuth => {
      // console.log('isAuth', isAuth);
      if (isAuth) {
        // console.log('data', isAuth);
        this.accountService.getUserRoles();
      }
    });
    let prevScrollPosition = window.scrollY;
    window.onscroll = function () {
      let currentScrollPos = window.scrollY;
      if (prevScrollPosition > currentScrollPos) {
        document.getElementById('sticky-top')!.style.top = '0';
        if (document.getElementById('sticky-first-position')) {
          document.getElementById('sticky-first-position')!.style.top = '115px';
        }
        if (document.getElementById('sticky-second-position')) {
          document.getElementById('sticky-second-position')!.style.top = '164px';
        }
      } else {
        document.getElementById('sticky-top')!.style.top = '-115px';
        if (document.getElementById('sticky-first-position')) {
          document.getElementById('sticky-first-position')!.style.top = '-1px';
        }
        if (document.getElementById('sticky-second-position')) {
          document.getElementById('sticky-second-position')!.style.top = '48px';
        }
      }
      prevScrollPosition = currentScrollPos;
    };
  }

  onRfqPage(): void {
    this.sharedService.closeMultiSelect.next(false);
    this.sharedService.closeAutoComplete.next(false);
  }

}
