import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotFoundComponent} from "./shared/not-found/not-found.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: ':lang/public',
    loadChildren: () => import('./public-pages/public-pages.module').then(m => m.PublicPagesModule),
  },
  {
    path: ':lang/account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  {
    path: ':lang/dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: ':lang/products',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
  },
  {
    path: ':lang/vendors',
    loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule),
  },
  {
    path: ':lang/rfq',
    loadChildren: () => import('./rfq/rfq.module').then(m => m.RfqModule),
  },  {
    path: ':lang/tv',
    loadChildren: () => import('./tv/tv.module').then(m => m.TvModule),
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
