import {Component, OnInit, Renderer2, Input, OnDestroy} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedService } from '../../shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Category } from '../../../category/category.model';
import { TokenStorageService } from '../../token.service';
import { environment } from '../../../../environments/environment';
import { CategoryService } from '../../../category/category.service';
import { AccountService } from '../../../account/account.service';
import { ProductService } from '../../../product/product.service';
import {SubSink} from "subsink";

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.sass'],
})
export class MobileHeaderComponent implements OnInit, OnDestroy {
  headerTitlesBehavioral = this.sharedService.headerTitles;
  searchTitleBehavioral = this.sharedService.searchTitles;
  headerTitles: any;
  searchTitles: any;
  searchType = 'product';
  search = new FormControl('', {
    nonNullable: true,
  });
  private subs = new SubSink();

  @Input() logo = '';
  @Input() direction = 'rtl';
  @Input() language = 'fa';
  @Input() isLoggedIn = false;
  @Input() categories: Category[] = [];
  fullName = '';

  isSearchOpen: boolean = false;
  isMenuOpen: boolean = false;
  isQuickLoginOpen: boolean = false;
  isLoginContext: boolean = false;

  menuTitles: any;
  categoriesMenuTitleBehavioral = this.sharedService.categoriesMenuTitles;
  categoriesMenuTitle: any;
  openedItemIndex = -1;
  products = [];

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private renderer: Renderer2,
    private tokenService: TokenStorageService,
    private categoryService: CategoryService,
    private accountService: AccountService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.subs.add(this.search.valueChanges.subscribe(data => {
      if (data.length > 2) {
        if (this.searchType === 'product') {
          this.productService.searchProduct(data).subscribe(products => {
            this.products = products;
            if (this.products.length > 0) {
              const element = document.getElementById('search-result-box');
              this.renderer.removeClass(element, 'invisible');
              this.renderer.addClass(element, 'visible');
            } else {
              const element = document.getElementById('search-result-box');
              this.renderer.removeClass(element, 'visible');
              this.renderer.addClass(element, 'invisible');
            }
          });
        }
      } else {
        const element = document.getElementById('search-result-box');
        this.renderer.removeClass(element, 'visible');
        this.renderer.addClass(element, 'invisible');
      }
    }));
  }

  ngOnInit(): void {
    this.subs.add(this.searchTitleBehavioral.subscribe(search => {
      this.searchTitles = search;
    }));
    this.subs.add(this.headerTitlesBehavioral.subscribe(header => {
      this.headerTitles = header;
    }));

    this.subs.add(this.sharedService.menuTitles.subscribe(titles => {
      this.menuTitles = titles;
    }));

    this.subs.add(this.categoriesMenuTitleBehavioral.subscribe(titles => {
      this.categoriesMenuTitle = titles;
    }));
  }

  onUserIcon() {
    this.router.navigate([this.language, 'dashboard']);
  }

  onMenuIcon() {
    this.isMenuOpen = !this.isMenuOpen;
    const element = document.getElementById('mobile-sidebar');
    if (this.isMenuOpen) {
      this.renderer.removeClass(element, 'invisible');
      this.renderer.addClass(element, 'visible');
      this.renderer.addClass(element, 'open');
    } else {
      this.renderer.removeClass(element, 'visible');
      this.renderer.removeClass(element, 'open');
      this.renderer.addClass(element, 'invisible');
    }
  }

  goToProductSearch(category: Category) {
    this.router.navigate([this.language, 'products'], {
      queryParams: {
        ...this.activatedRoute.snapshot.queryParams,
        categoryId: category.Id,
        categoryFilter: true,
        page: '1'
      }
    });
  }

  onSearchIcon() {
    this.isSearchOpen = !this.isSearchOpen;
    const element = document.getElementById('search-box-mobile');
    if (this.isSearchOpen) {
      this.renderer.removeClass(element, 'invisible');
      this.renderer.addClass(element, 'visible');
      this.renderer.addClass(element, 'open');
    } else {
      this.renderer.removeClass(element, 'visible');
      this.renderer.removeClass(element, 'open');
      this.renderer.addClass(element, 'invisible');
    }
  }

  onLoginIcon() {
    this.isLoginContext = !this.isLoginContext;
  }

  onOpenAccordion(i: number) {
    if (i === this.openedItemIndex) {
      this.openedItemIndex = -1;
    } else {
      this.openedItemIndex = i;
    }
  }

  signOut() {
    this.onMenuIcon();
    this.accountService.logout();
  }

  getQueryParams(category: Category) {
    return {
      ...this.activatedRoute.snapshot.queryParams,
      categoryId: category.Id,
    }
  }

  onSearch(): void {
    if (this.searchType === 'product') {
      this.router
        .navigate([this.language, 'products'], {
          // queryParams: {
          //   ...this.activatedRoute.snapshot.queryParams,
          //   page: 1,
          //   search: this.search.value
          // },
          queryParams: {
            page: 1,
            search: this.search.value
          },
        });
    } else {
      this.router
        .navigate([this.language, 'vendors'], {
          queryParams: {
            ...this.activatedRoute.snapshot.queryParams,
            page: 1,
            search: this.search.value
          },
        })
        .then();
    }
    this.onSearchIcon();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
