import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import { Category } from 'src/app/category/category.model';
import { SharedService } from '../../shared.service';
import {ActivatedRoute, Router} from "@angular/router";
import {SubSink} from "subsink";

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.sass'],
})
export class AccordionComponent implements OnDestroy {
  @Input() category: any;
  @Output() menuOpen = new EventEmitter();
  openedItemIndex = -1;
  language = 'fa';
  private subs = new SubSink();
  constructor(private sharedService: SharedService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.subs.add(this.sharedService.currentLanguage.subscribe(language => {
      this.language = language;
    }));
  }

  onAccordionLink() {
    this.menuOpen.emit(false);
  }

  goToProductSearch(category: Category) {
    this.menuOpen.emit(false);
    this.router.navigate([this.language, 'products'], {
      queryParams: {
        ...this.activatedRoute.snapshot.queryParams,
        categoryId: category.Id,
        categoryFilter: true,
        page: '1'
      }
    });
  }

  onOpenAccordion(i: number) {
    if (i === this.openedItemIndex) {
      this.openedItemIndex = -1;
    } else {
      this.openedItemIndex = i;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
