<div
  class="d-flex flex-column position-relative"
  [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
  [dir]="direction === 'rtl' ? 'rtl' : 'ltr'"
  (click)="onRfqPage()"

>
  <app-header class="sticky-top" id="sticky-top"></app-header>
  <app-menu></app-menu>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  <app-notification></app-notification>
  <app-back-to-top></app-back-to-top>
  <app-rfq-basket *ngIf="rfqBasketProducts.length > 0"></app-rfq-basket>
</div>

