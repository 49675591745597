import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';
import Swal from 'sweetalert2';
import { SharedService } from '../../shared.service';
import { environment } from '../../../../environments/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';
import {SubSink} from "subsink";

@Component({
  selector: 'app-quick-login',
  templateUrl: './quick-login.component.html',
  styleUrls: ['./quick-login.component.sass'],
})
export class QuickLoginComponent implements OnDestroy, OnInit {
  loginTitles: any;
  apiTitles: any;
  emailController = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.email],
  });
  passwordController = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required],
  });
  rememberMe = new FormControl<boolean>(false, {
    nonNullable: true,
  });

  loginForm: FormGroup = this.formBuilder.group({
    emailController: this.emailController,
    passwordController: this.passwordController,
    rememberMe: this.rememberMe,
  });

  direction = 'rtl';
  language = 'fa';
  showResendEmail = false;
  private subs = new SubSink();
  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private renderer: Renderer2
  ) {
    this.subs.add(this.sharedService.apiTitles.subscribe(titles => {
      this.apiTitles = titles;
    }));
    this.subs.add(this.accountService.needConfirmEmail.subscribe(needConfirmation => {
      this.showResendEmail = needConfirmation;
    }));
    this.subs.add(this.sharedService.siteDirection.subscribe(direction => {
      this.direction = direction;
    }));
    this.subs.add(this.sharedService.loginTitles.subscribe(titles => {
      this.loginTitles = titles;
    }));
  }

  ngOnInit() {
    this.subs.add(this.accountService.sessionWasFull.subscribe(isSessionFull => {
      if (isSessionFull) {
        this.router.navigate(['/', this.language, 'account', 'logged-session']);
        const element = document.getElementById('quick-login');
        this.renderer.addClass(element, 'invisible');
        this.renderer.removeClass(element, 'visible');
      }
    }));
  }

  login(): void {
    this.loginForm.markAllAsTouched();
    if (!this.emailController.errors && !this.passwordController.errors) {
      let shouldNavigate = false;
      if (this.router.url.includes('login') || this.router.url.includes('register')) {
        shouldNavigate = true;
      }
      this.recaptchaV3Service.execute(environment.captchaKey, 'Login', token => {
        this.accountService.login(
          this.emailController.value,
          this.passwordController.value,
          this.rememberMe.value,
          token,
          shouldNavigate
        );
        this.accountService.isEmailConfirmed(this.emailController.value);
      });
    } else {
      let error = '';
      if (this.emailController.hasError('required')) {
        error += this.loginTitles.email_required + '<br>';
      }
      if (this.emailController.hasError('email')) {
        error += this.loginTitles.email_invalid + '<br>';
      }
      if (this.passwordController.hasError('required')) {
        error += this.loginTitles.password_required + '<br>';
      }
      Swal.fire({
        title: 'Login',
        html: error,
        confirmButtonText: this.apiTitles.confirm_button,
        icon: 'error',
      });
    }
  }
  sendEmail(): void {
    this.loginForm.markAllAsTouched();
    if (!this.emailController.errors && !this.passwordController.errors) {
      this.accountService.resendConfirmEmail(this.emailController.value, this.passwordController.value);
    } else {
      let error = '';
      if (this.emailController.hasError('required')) {
        error += this.loginTitles.email_required + '<br>';
      }
      if (this.emailController.hasError('email')) {
        error += this.loginTitles.email_invalid + '<br>';
      }
      if (this.passwordController.hasError('required')) {
        error += this.loginTitles.password_required + '<br>';
      }
      Swal.fire({
        title: 'Login',
        html: error,
        confirmButtonText: this.apiTitles.confirm_button,
        icon: 'error',
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
