import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { environment } from '../../environments/environment';
import { Vendor, VendorProfile } from './vendor.model';
import { Picture } from '../product/models/picture.model';
import { Product } from '../product/product.model';
import moment from 'jalali-moment';
import { Unit } from '../shared/models/unit.model';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  units: Unit[] = [];
  constructor(private httpClient: HttpClient, private sharedService: SharedService) {
    this.sharedService.units.subscribe(units => {
      this.units = units;
    });
  }

  //#region API's Url
  baseVendorSearchUrl = '/api/v1/WebVendor/SearchVendor';
  baseVendorAdvancedSearchUrl = '/api/v1/WebVendor/WebAdvancedSearchVendor';
  baseVendorProfileUrl = '/api/v1/WebVendor/getVendorInformation/';
  baseVendorPictureUrl = '/api/v1/Picture/GetAllEntityPicturesForVendor';
  baseVendorProductsUrl = '/api/v1/WebProduct/GetVendorProductList/';
  baseUrlVendorTicket = '/api/v1/WebTicketRFQ/SendRFQToVendor/';
  baseUrlFilter = '/api/v1/WebVendor/GetAllBasicInformation';

  //#endregion

  //#region Properties
  certificateFilters = new BehaviorSubject<any[]>([]);
  levelFilters = new BehaviorSubject<any[]>([]);
  vendorCertificates = new BehaviorSubject<Picture[]>([]);
  searchCount = new BehaviorSubject<number>(0);
  vendors = new ReplaySubject<VendorProfile[]>(1);
  searchVendors = new ReplaySubject<VendorProfile[]>(1);
  vendorProductList = new BehaviorSubject<Product[]>([]);
  vendorClear = new BehaviorSubject<boolean>(false);
  vendorProfile = new BehaviorSubject<any>({
    Addresses: [],
    Detail: undefined,
    address: '',
    fullName: '',
    full_description: '',
    pictures: [],
    post: '',
    short_description: '',
    subject: '',
    videos: [],
  });
  //#endregion

  //#region Methods
  getVendorListFilter(): void {
    this.httpClient
      .get(this.baseUrlFilter)
      .subscribe((response: any) => {
        const certificates = [];
        const levels = [];
        for (const item of response.jsonResult.Data.ManagementCertificate) {
          if (!item.Deleted) {
            certificates.push({
              value: item.Id,
              name: item.Name,
            });
          }
        }
        for (const item of response.jsonResult.Data.VendorLevel) {
          if (!item.Deleted) {
            levels.push({
              value: item.Id,
              name: item.LevelName,
            });
          }
        }
        this.levelFilters.next(levels);
        this.certificateFilters.next(certificates);
      });
  }

  calculateDiff(dateSent: any): number {
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.floor(
      (Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) -
        Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  }

  getVendors(data: any): void {
    this.searchVendors.next([]);
    this.httpClient
      .get(this.baseVendorSearchUrl, {
        headers: {
          value: data,
        },
      })
      .subscribe((response: any) => {
        let items = response.jsonResult.Data.vendor;
        this.searchCount.next(response.jsonResult.Data.rowcount);
        if (items.length == 0) return items;
        let vendors: any[] = [];
        for (let item of items) {
          let vendor: any = item;
          if (vendor == undefined) return item;
          let Temp = undefined;
          try {
            Temp = vendor.VendorInformation.FullName![this.sharedService.language]
              ? vendor.VendorInformation.FullName![this.sharedService.language]
              : vendor.VendorInformation.FullName!['fa'];
          } catch (e) {}
          vendor.VendorInformation.FullName = Temp ?? vendor.VendorInformation.FullName;
          try {
            Temp = vendor.VendorInformation.Subject![this.sharedService.language]
              ? vendor.VendorInformation.Subject![this.sharedService.language]
              : vendor.VendorInformation.Subject!['fa'];
          } catch (e) {}
          vendor.VendorInformation.Subject = Temp ?? vendor.VendorInformation.Subject;
          try {
            Temp = vendor.VendorInformation.ShortDescription![this.sharedService.language]
              ? vendor.VendorInformation.ShortDescription![this.sharedService.language]
              : vendor.VendorInformation.ShortDescription!['fa'];
          } catch (e) {}
          vendor.VendorInformation.ShortDescription = Temp ?? vendor.VendorInformation.ShortDescription;
          if (vendor.Pictures!) for (let picture of vendor.Pictures) picture.Path = environment.apiUrl + picture.Path;
          vendors.push(vendor);
        }
        // this.vendors.next(vendors);
        this.searchVendors.next(vendors);
      });
  }

  getAdvancedVendors(data: any): void {
    this.httpClient
      .get(this.baseVendorAdvancedSearchUrl, {
        headers: {
          value: data,
        },
      })
      .subscribe((response: any) => {
        let items = response.jsonResult.Data.vendor;
        this.searchCount.next(response.jsonResult.Data.rowcount);
        let vendors: any[] = [];
        for (let item of items) {
          let vendor: any = item;
          if (vendor == undefined) return item;
          let Temp = undefined;
          try {
            Temp = vendor.VendorInformation.FullName![this.sharedService.language]
              ? vendor.VendorInformation.FullName![this.sharedService.language]
              : vendor.VendorInformation.FullName!['fa'];
          } catch (e) {}
          vendor.VendorInformation.FullName = Temp ?? vendor.VendorInformation.FullName;
          try {
            Temp = vendor.VendorInformation.Subject![this.sharedService.language]
              ? vendor.VendorInformation.Subject![this.sharedService.language]
              : vendor.VendorInformation.Subject!['fa'];
          } catch (e) {}
          vendor.VendorInformation.Subject = Temp ?? vendor.VendorInformation.Subject;
          try {
            Temp = vendor.VendorInformation.ShortDescription![this.sharedService.language]
              ? vendor.VendorInformation.ShortDescription![this.sharedService.language]
              : vendor.VendorInformation.ShortDescription!['fa'];
          } catch (e) {}
          vendor.VendorInformation.ShortDescription = Temp ?? vendor.VendorInformation.ShortDescription;
          // if (vendor.Picture!) for (let picture of vendor.Picture) picture.Path = environment.apiUrl + picture.Path;
          vendors.push(vendor);
        }
        this.vendors.next(vendors);
      });
  }

  getVendor(guid: string): void {
    this.httpClient.get(this.baseVendorProfileUrl + guid).subscribe((response: any) => {
      let vendor: Vendor = response.jsonResult.Data;
      let temp = undefined;
      try {
        try {
          while (vendor.Detail.FullProfile['fa'].indexOf('$') != -1)
            vendor.Detail.FullProfile['fa'] = vendor.Detail.FullProfile['fa'].replace('$', "'");
        } catch (e) {}

        try {
          while (vendor.Detail.FullProfile['en'].indexOf('$') != -1)
            vendor.Detail.FullProfile['en'] = vendor.Detail.FullProfile['en'].replace('$', "'");
        } catch (e) {}
        try {
          temp = vendor.Detail.FullProfile[this.sharedService.language]
            ? vendor.Detail.FullProfile[this.sharedService.language]
            : vendor.Detail.FullProfile['fa'];
        } catch (e) {}
      } catch (e) {
        try {
          while (vendor.Detail.FullProfile.indexOf('$') != -1) temp = vendor.Detail.FullProfile.replace('$', "'");
        } catch (e) {}
      }

      vendor.Detail.DateDiff = Math.floor(this.calculateDiff(vendor.Detail.CreatedOnUtc) / 365);
      if (vendor.Detail.DateDiff == 0) {
        vendor.Detail.DateDiff = Math.floor(this.calculateDiff(vendor.Detail.CreatedOnUtc) / 30);
        if (vendor.Detail.DateDiff == 0) {
          vendor.Detail.DateDiff = this.calculateDiff(vendor.Detail.CreatedOnUtc);
          vendor.Detail.DateDiffRange = 3;
        } else vendor.Detail.DateDiffRange = 2;
      } else vendor.Detail.DateDiffRange = 1;

      vendor.Detail.FullProfile = temp ?? vendor.Detail.FullProfile;
      const year = +moment(vendor.Detail?.CreatedOnUtc, 'DD/MM/YYYY HH:MM:SS').format('YYYY');
      if (year) {
        vendor.Year = new Date().getFullYear() - year + 1;
      } else {
        vendor.Year = 1;
      }
      try {
        temp = vendor.Detail.FullName[this.sharedService.language]
          ? vendor.Detail.FullName[this.sharedService.language]
          : vendor.Detail.FullName['fa'];
      } catch (e) {}

      vendor.Detail.FullName = temp ?? vendor.Detail.FullName;

      try {
        temp = vendor.Detail.Subject[this.sharedService.language]
          ? vendor.Detail.Subject[this.sharedService.language]
          : vendor.Detail.Subject['fa'];
      } catch (e) {}

      vendor.Detail.Subject = temp ?? vendor.Detail.Subject;

      try {
        temp = vendor.Detail.ShortDescription[this.sharedService.language]
          ? vendor.Detail.ShortDescription[this.sharedService.language]
          : vendor.Detail.ShortDescription['fa'];
      } catch (e) {}

      vendor.Detail.ShortDescription = temp ?? vendor.Detail.ShortDescription;

      try {
        temp = vendor.Detail.FullDescription[this.sharedService.language]
          ? vendor.Detail.FullDescription[this.sharedService.language]
          : vendor.Detail.FullDescription['fa'];
      } catch (e) {}

      vendor.Detail.FullDescription = temp ?? vendor.Detail.FullDescription;

      try {
        temp = vendor.Detail.ContactPerson[this.sharedService.language]
          ? vendor.Detail.ContactPerson[this.sharedService.language]
          : vendor.Detail.ContactPerson['fa'];
      } catch (e) {}

      vendor.Detail.ContactPerson = temp ?? vendor.Detail.ContactPerson;

      try {
        temp = vendor.Detail.LegalStatus[this.sharedService.language]
          ? vendor.Detail.LegalStatus[this.sharedService.language]
          : vendor.Detail.LegalStatus['fa'];
      } catch (e) {}

      vendor.Detail.LegalStatus = temp ?? vendor.Detail.LegalStatus;

      try {
        temp = vendor.Detail.BusinessType[this.sharedService.language]
          ? vendor.Detail.BusinessType[this.sharedService.language]
          : vendor.Detail.BusinessType['fa'];
      } catch (e) {}

      vendor.Detail.BusinessType = temp ?? vendor.Detail.BusinessType;

      try {
        temp = vendor.Detail.Refrences[this.sharedService.language]
          ? vendor.Detail.Refrences[this.sharedService.language]
          : vendor.Detail.Refrences['fa'];
      } catch (e) {}

      vendor.Detail.Refrences = temp ?? vendor.Detail.Refrences;

      for (let item of vendor.Addresses) {
        temp = item.Address![this.sharedService.language]
          ? item.Address![this.sharedService.language]
          : item.Address!['fa'];
        item.Address = temp ?? item.Address;

        temp = item.Title![this.sharedService.language] ? item.Title![this.sharedService.language] : item.Title!['fa'];
        item.Title = temp ?? item.Title;

        temp = item.GeoLocationName![this.sharedService.language]
          ? item.GeoLocationName![this.sharedService.language]
          : item.GeoLocationName!['fa'];
        item.GeoLocationName = temp ?? item.GeoLocationName;
      }

      try {
        let FAQList = vendor.Detail.FAQ;

        vendor.Detail.FAQ = [];
        for (let faq of FAQList) {
          vendor.Detail.FAQ.push({
            question: faq.question[this.sharedService.language]
              ? faq.question[this.sharedService.language]
              : faq.question,
            answer: faq.answer[this.sharedService.language] ? faq.answer[this.sharedService.language] : faq.answer,
            // id: faq.id,
          });
        }
      } catch (e) {}
      const pictures = [];
      for (let picture of vendor.pictures!) {
        if (!picture.IsThumbnail && picture.Type === 2) {
          picture.Path = environment.apiUrl + picture.Path;
          pictures.push(picture);
        } else if (!picture.IsThumbnail && picture.Type === 1) {
          vendor.avatar = environment.apiUrl + picture.Path;
          vendor.logo = picture;
        }
      }
      vendor.pictures = pictures;
      for (const capacity of vendor.Detail.ProductionCapacity) {
        const interval = setInterval(() => {
          if (this.units.length > 0) {
            capacity.unit = this.units.find(u => (u.Id = capacity.unit))!.Name;
            clearInterval(interval);
          }
        }, 1000);
      }
      this.vendorProfile.next(vendor);
    });
  }

  getVendorImages(guid: string, entityName: string): void {
    const GetAllEntityPictures = JSON.stringify({ VendorGuid: guid, EntityName: entityName });
    this.httpClient
      .get(this.baseVendorPictureUrl, {
        headers: {
          value: GetAllEntityPictures,
        },
      })
      .subscribe((response: any) => {
        const pictures = [];
        for (let item of response.jsonResult.Data.picture) {
          if (!item.IsThumbnail) {
            const picture: Picture = item;
            // picture.Path = environment.apiUrl + picture.Path;
            pictures.push(picture);
          }
        }
        switch (entityName) {
          case 'VendorCertificate': {
            this.vendorCertificates.next(pictures);
            break;
          }
        }
      });
  }

  sendMessage(Message: any): Observable<any> {
    return this.httpClient.post<any>(this.baseUrlVendorTicket, Message);
  }

  getVendorProducts(guid: string, filter: any): void {
    this.httpClient
      .get(this.baseVendorProductsUrl + guid, {
        headers: {
          value: filter,
        },
      })
      .subscribe((response: any) => {
        const vendorProducts = [];

        for (let item of response.jsonResult.Data) {
          let product: Product = item;

          if (product == undefined) continue;

          product.Detail!.currentProductName = product.Detail!.ProductName[this.sharedService.language]
            ? product.Detail!.ProductName[this.sharedService.language]
            : product.Detail!.ProductName['fa'];

          product.Detail!.currentProductName = product.Detail!.currentProductName ?? product.Detail!.ProductName;

          for (let cat of product.category!) {
            cat.currentCategory = cat.CatName[this.sharedService.language]
              ? cat.CatName[this.sharedService.language]
              : cat.CatName['fa'];
            cat.currentCategory = cat.currentCategory ?? cat.CatName;
          }

          product.picture.Path = environment.apiUrl + product.picture.Path;

          vendorProducts.push(product);
        }

        this.vendorProductList.next(vendorProducts);
      });
  }
  //#endregion
}
