import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.sass'],
})
export class BackToTopComponent implements OnInit {
  windowScrolled: boolean = false;

  constructor() {}

  ngOnInit(): void {
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  showButton() {
    if (this.windowScrolled) {
      return 'show-scrollTop';
    } else {
      return '';
    }
  }
}
