<div
  class="menu container d-flex flex-column flex-xl-row border-small-radius border-radius-sm-none bg-light-blue white p-2"
  *ngIf="menuTitles && categoriesMenuTitle"
  [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
  [dir]="direction === 'rtl' ? 'rtl' : 'ltr'"
>
  <div class="col-xl-9 col-lg-12 d-none d-lg-flex justify-content-center justify-content-xl-start align-items-center">
    <div class="category position-relative" [ngClass]="{ 'disable-category-hover': disableCategoryHover }" (mouseenter)="disableCategoryHover = false" >
      <img src="../../../assets/icons/menu.svg" alt="menu" />
      <span class="margin fs-14 cursor-pointer">{{ categoriesMenuTitle.shop_by_department }}</span>
      <div class="category-menu flex-column" >
        <div
          class="category-item d-flex flex-row align-items-center position-relative py-1 px-2"
          *ngFor="let category of categories"
          [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
        >
          <i class="fontello" *ngIf="category.CssClass" [ngClass]="category.CssClass"></i>
          <a
            class="category-link fs-14 text-truncate text-justify"
            (click)="goToProductSearch(category)"
            [title]="category.currentName"
          >
            {{ category.currentName }}
          </a>
          <div class="category-sub-menu flex-column">
            <div
              class="d-flex flex-row align-items-center position-relative category-sub-menu-item py-1 px-2"
              *ngFor="let subCategory of category.Childs"
              [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
            >
              <i class="fontello" *ngIf="subCategory.CssClass" [ngClass]="subCategory.CssClass"></i>
              <a
                class="fs-14 text-truncate text-justify sub-category-link"
                (click)="goToProductSearch(subCategory)"
                [title]="subCategory.currentName"
                >{{ subCategory.currentName }}</a
              >
              <div *ngIf="subCategory.Childs.length > 0" class="category-sub-sub-menu d-flex flex-column">
                <div
                  class="d-flex flex-row align-items-center category-sub-sub-menu-item py-1 px-2"
                  *ngFor="let subSubCategory of subCategory.Childs"
                  [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'"
                >
                  <i class="fontello" *ngIf="subSubCategory?.CssClass" [ngClass]="subSubCategory?.CssClass"></i>
                  <a
                    class="fs-14 text-truncate text-justify second-sub-category-link"
                    (click)="goToProductSearch(subSubCategory)"
                    [title]="subSubCategory.Name"
                    >{{ subSubCategory.Name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="white medium-margin fs-14" [routerLink]="[language, 'public', 'transport']">{{ menuTitles.shipping }}</a>
    <a class="white medium-margin fs-14" [routerLink]="[language, 'public', 'customs']">{{
      menuTitles.customs_services
    }}</a>
    <a class="white medium-margin fs-14" [routerLink]="[language, 'public', 'bank-credit']">{{
      menuTitles.bankCredit
    }}</a>
    <a class="white medium-margin fs-14" [routerLink]="[language, 'public', 'insurance']">{{ menuTitles.insurance }}</a>
    <a class="white medium-margin fs-14" [routerLink]="[language, 'tv']">{{ menuTitles.tv }}</a>
  </div>
  <div class="col-12 col-xl-3 d-flex flex-wrap justify-content-center justify-content-xl-end mt-lg-3 mt-xl-0">
    <div class="d-flex flex-row flex-nowrap justify-content-end">
      <button class="btn btn-primary fs-14 margin mb-1" *ngIf="isVendor" (click)="goToVendor()">
        {{ headerTitles.vendor_panel }}
      </button>
      <button class="btn btn-primary fs-14 margin mb-1" *ngIf="isCustomer" (click)="goToCustomer()">
        {{ headerTitles.customer_panel ?  headerTitles.customer_panel : 'پنل مشتری' }}
      </button>
      <button class="btn btn-primary fs-14 margin mb-1" *ngIf="isTax">{{ menuTitles.tax_panel }}</button>
    </div>
    <div class="d-flex flex-row flex-nowrap justify-content-end">
      <a class="border-small-radius bg-orange white margin p-2 d-flex mb-1" (click)="goToRFQ()"
        >RFQ</a
      >
      <div class="d-flex flex-row align-items-center margin">
        <label class="fs-14">{{ menuTitles.language }}</label>
        <select
          id="language-dropdown"
          class="form-select fs-14 bg-transparent search-type pointer margin"
          (change)="onLanguageChange()"
          [(ngModel)]="currentLanguage"
          [ngModelOptions]="{ standalone: true }"
        >
          <option class="medium-gray pointer fs-14 d-none" selected disabled >{{ currentLanguage }}</option>
          <option class="medium-gray pointer fs-14" *ngFor="let lang of siteLanguages" [value]="lang.name">
            {{ lang.name }}
          </option>
        </select>
      </div>
      <!--      <div class="d-flex flex-row align-items-center margin">-->
      <!--        <label class="fs-14">{{ menuTitles.money }}:</label>-->
      <!--        <select-->
      <!--          id="money-dropdown"-->
      <!--          class="form-select fs-14 bg-transparent search-type pointer margin"-->
      <!--          (change)="onMoneyChange()"-->
      <!--          [(ngModel)]="currentMoney"-->
      <!--          [ngModelOptions]="{ standalone: true }"-->
      <!--        >-->
      <!--          <option class="medium-gray pointer fs-14" *ngFor="let money of siteMonies" [value]="money.name">-->
      <!--            {{ money.name }}-->
      <!--          </option>-->
      <!--        </select>-->
      <!--      </div>-->
    </div>
  </div>
</div>
