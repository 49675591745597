import { Injectable } from '@angular/core';
import { Language } from './language/language.model';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Title } from './helper/title.model';
import { Unit } from './models/unit.model';
import { ProductRFQ } from '../product/models/product.rfq.model';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  //##region Api Urls
  baseTitlesUrl: string = '/api/v1/LocaleStringResource/GetResourceByCategory';
  baseGalleryUrl: string = '/api/v1/WebGallery/GetPictureListByPosition/';
  baseGeoLocationUrl: string = '/api/v1/WebGeoLocation/SearchGeoLocation';
  baseUnitsUrl: string = '/api/v1/WebUnit/GetAllPublicUnits';
  baseRFQUrl: string = '/api/v1/Ticket/TicketToFarahoosh';
  baseContactUsUrl: string = '/api/v1/WebContactUs/WebAddContactUs';
  baseFAQUrl: string = '/api/v1/WebSiteFAQ/GetAllRows';
  baseLanguageUrl: string = '/api/v1/Language/GetActiveLanguagesListObject';
  baseIPLanguageUrl: string = '/api/v1/WebIPToLocation/GetSiteLanguage';
  baseUrlStaticPage: string = '/api/V1/WebStaticPage/GetStaticPageByName';
  //##endregion

  //##region Properties
  language = 'fa';
  siteLanguages: Language[] = [
    { name: 'فارسی', title: 'fa', direction: 'rtl' },
    { name: 'English', title: 'en', direction: 'ltr' },
    { name: 'العربیه', title: 'ar', direction: 'rtl' },
    { name: 'français', title: 'fr', direction: 'ltr' },
  ];
  //##end region

  //##region Streams
  currentLanguage = new BehaviorSubject<string>(
    localStorage.getItem('language') ? localStorage.getItem('language') + '' : 'en'
  );
  currentMoney = new BehaviorSubject<string>(
    localStorage.getItem('money') ? localStorage.getItem('money') + '' : 'Dollar'
  );
  siteDirection = new BehaviorSubject<string>(
    localStorage.getItem('direction') ? localStorage.getItem('direction') + '' : 'ltr'
  );
  languages = new BehaviorSubject<Language[]>(this.siteLanguages);
  units = new BehaviorSubject<Unit[]>([]);
  menuTitles = new BehaviorSubject<any>({});
  loginTitles = new BehaviorSubject<any>({});
  registerTitles = new BehaviorSubject<any>({});
  confirmEmailTitles = new BehaviorSubject<any>({});
  forgetPasswordTitles = new BehaviorSubject<any>({});
  accountTitles = new BehaviorSubject<any>({});
  accountInformationTitles = new BehaviorSubject<any>({});
  companyAccountInformationTitles = new BehaviorSubject<any>({});
  userRFQTitles = new BehaviorSubject<any>({});
  headerTitles = new BehaviorSubject<any>({});
  footerTitles = new BehaviorSubject<any>({});
  searchTitles = new BehaviorSubject<any>({});
  homeTitles = new BehaviorSubject<any>({});
  serviceTitles = new BehaviorSubject<any>({});
  categoriesMenuTitles = new BehaviorSubject<any>({});
  sliderProductTitles = new BehaviorSubject<any>({});
  addressTitles = new BehaviorSubject<any>({});
  aboutUsTitles = new BehaviorSubject<any>({});
  magazineTitles = new BehaviorSubject<any>({});
  productSearchTitles = new BehaviorSubject<any>({});
  apiTitles = new BehaviorSubject<any>({});
  vendorSearchTitles = new BehaviorSubject<any>({});
  breadcrumbTitles = new BehaviorSubject<any>({});
  productMainTitles = new BehaviorSubject<any>({});
  alertTitles = new BehaviorSubject<any>({});
  productDetailTitles = new BehaviorSubject<any>({});
  vendorProfileTitles = new BehaviorSubject<any>({});
  vendorDetailsTitles = new BehaviorSubject<any>({});
  resetPasswordTitles = new BehaviorSubject<any>({});
  publicTitles = new BehaviorSubject<any>({});
  rfqTitles = new BehaviorSubject<any>({});
  tvListTitles = new BehaviorSubject<any>({});
  contactUsTitles = new BehaviorSubject<any>({});
  quickViewOpen = new BehaviorSubject<boolean>(false);
  closeMultiSelect = new BehaviorSubject<boolean>(false);
  closeAutoComplete = new BehaviorSubject<boolean>(false);
  quickViewProduct = new Subject<string>();
  rfqBasketProducts = new BehaviorSubject<ProductRFQ[]>([]);
  contactSupplierProducts = new BehaviorSubject<ProductRFQ[]>([]);
  menuTitlesLanguage = '';
  loginTitlesLanguage = '';
  registerTitlesLanguage = '';
  confirmEmailTitlesLanguage = '';
  forgetPasswordTitlesLanguage = '';
  accountTitlesLanguage = '';
  accountInformationTitlesLanguage = '';
  companyAccountInformationTitlesLanguage = '';
  userRFQTitlesLanguage = '';
  headerTitlesLanguage = '';
  footerTitlesLanguage = '';
  searchTitlesLanguage = '';
  homeTitlesLanguage = '';
  serviceTitlesLanguage = '';
  categoriesMenuTitlesLanguage = '';
  sliderProductTitlesLanguage = '';
  addressTitlesLanguage = '';
  aboutUsTitlesLanguage = '';
  magazineTitlesLanguage = '';
  productSearchTitlesLanguage = '';
  apiTitlesLanguage = '';
  alertTitlesLanguage = '';
  vendorSearchTitlesLanguage = '';
  breadcrumbTitlesLanguage = '';
  productMainTitlesLanguage = '';
  productDetailTitlesLanguage = '';
  vendorProfileTitlesLanguage = '';
  vendorDetailsTitlesLanguage = '';
  resetPasswordTitlesLanguage = '';
  rfqTitlesLanguage = '';
  publicTitlesLanguage = '';
  tvListTitlesLanguage = '';
  contactUsTitlesLanguage = '';
  //##endregion

  constructor(private httpClient: HttpClient) {}

  //##region Methods
  onLanguageChanges(language: string, section: string): void {
    if (language === undefined) {
      return;
    }
    // prevent duplicate call when language is not changed
    switch (section) {
      case 'menuTitle': {
        if (this.menuTitlesLanguage === language) return;
        else this.menuTitlesLanguage = language;
        break;
      }
      case 'loginTitle': {
        if (this.loginTitlesLanguage === language) return;
        else this.loginTitlesLanguage = language;
        break;
      }
      case 'registerTitle': {
        if (this.registerTitlesLanguage === language) return;
        else this.registerTitlesLanguage = language;
        break;
      }
      case 'confirmEmailTitle': {
        if (this.confirmEmailTitlesLanguage === language) return;
        else this.confirmEmailTitlesLanguage = language;
        break;
      }
      case 'alertTitle': {
        if (this.alertTitlesLanguage === language) {
          return;
        } else {
          this.alertTitlesLanguage = language;
        }
        break;
      }
      case 'forgetPasswordTitle': {
        if (this.forgetPasswordTitlesLanguage === language) return;
        else this.forgetPasswordTitlesLanguage = language;
        break;
      }
      case 'resetPasswordTitle': {
        if (this.resetPasswordTitlesLanguage === language) return;
        else this.resetPasswordTitlesLanguage = language;
        break;
      }
      case 'accountTitle': {
        if (this.accountTitlesLanguage === language) return;
        else this.accountTitlesLanguage = language;
        break;
      }
      case 'accountInformationTitle': {
        if (this.accountInformationTitlesLanguage === language) return;
        else this.accountInformationTitlesLanguage = language;
        break;
      }
      case 'companyAccountInformationTitle': {
        if (this.companyAccountInformationTitlesLanguage === language) return;
        else this.companyAccountInformationTitlesLanguage = language;
        break;
      }
      case 'userRFQTitle': {
        if (this.userRFQTitlesLanguage === language) return;
        else this.userRFQTitlesLanguage = language;
        break;
      }
      case 'headerTitle': {
        if (this.headerTitlesLanguage === language) return;
        else this.headerTitlesLanguage = language;
        break;
      }
      case 'footerTitle': {
        if (this.footerTitlesLanguage === language) return;
        else this.footerTitlesLanguage = language;
        break;
      }
      case 'searchTitle': {
        if (this.searchTitlesLanguage === language) return;
        else this.searchTitlesLanguage = language;
        break;
      }
      case 'homeTitle': {
        if (this.homeTitlesLanguage === language) return;
        else this.homeTitlesLanguage = language;
        break;
      }
      case 'serviceTitle': {
        if (this.serviceTitlesLanguage === language) return;
        else this.serviceTitlesLanguage = language;
        break;
      }
      case 'addressTitle': {
        if (this.addressTitlesLanguage === language) return;
        else this.addressTitlesLanguage = language;
        break;
      }
      case 'dayOfferTitle': {
        if (this.sliderProductTitlesLanguage === language) return;
        else this.sliderProductTitlesLanguage = language;
        break;
      }
      case 'categoriesMenuTitle': {
        if (this.categoriesMenuTitlesLanguage === language) return;
        else this.categoriesMenuTitlesLanguage = language;
        break;
      }
      case 'aboutUsTitle': {
        if (this.aboutUsTitlesLanguage === language) return;
        else this.aboutUsTitlesLanguage = language;
        break;
      }
      case 'magazineTitle': {
        if (this.magazineTitlesLanguage === language) return;
        else this.magazineTitlesLanguage = language;
        break;
      }
      case 'productSearchTitle': {
        if (this.productSearchTitlesLanguage === language) return;
        else this.productSearchTitlesLanguage = language;
        break;
      }
      case 'apiTitle': {
        if (this.apiTitlesLanguage === language) return;
        else this.apiTitlesLanguage = language;
        break;
      }
      case 'vendorSearchTitle': {
        if (this.vendorSearchTitlesLanguage === language) return;
        else this.vendorSearchTitlesLanguage = language;
        break;
      }
      case 'breadcrumbTitle': {
        if (this.breadcrumbTitlesLanguage === language) return;
        else this.breadcrumbTitlesLanguage = language;
        break;
      }
      case 'productMainTitle': {
        if (this.productMainTitlesLanguage === language) return;
        else this.productMainTitlesLanguage = language;
        break;
      }
      case 'productDetailsTitle': {
        if (this.productDetailTitlesLanguage === language) return;
        else this.productDetailTitlesLanguage = language;
        break;
      }
      case 'vendorProfileTitle': {
        if (this.vendorProfileTitlesLanguage === language) return;
        else this.vendorProfileTitlesLanguage = language;
        break;
      }
      case 'vendorDetailsTitle': {
        if (this.vendorDetailsTitlesLanguage === language) return;
        else this.vendorDetailsTitlesLanguage = language;
        break;
      }
      case 'rfqTitle': {
        if (this.rfqTitlesLanguage === language) return;
        else this.rfqTitlesLanguage = language;
        break;
      }
      case 'publicTitle': {
        if (this.publicTitlesLanguage === language) return;
        else this.publicTitlesLanguage = language;
        break;
      }
      case 'tvListTitle': {
        if (this.tvListTitlesLanguage === language) return;
        else this.tvListTitlesLanguage = language;
        break;
      }
      case 'contactUsTitle': {
        if (this.contactUsTitlesLanguage === language) return;
        else this.contactUsTitlesLanguage = language;
        break;
      }
    }
    this.language = language;
    // get resource translation
    this.getTitles(language, section).subscribe(titles => {
      switch (section) {
        case 'menuTitle': {
          this.menuTitles.next(titles);
          break;
        }
        case 'loginTitle': {
          this.loginTitles.next(titles);
          break;
        }
        case 'registerTitle': {
          this.registerTitles.next(titles);
          break;
        }
        case 'alertTitle': {
          this.alertTitles.next(titles);
          break;
        }
        case 'confirmEmailTitle': {
          this.confirmEmailTitles.next(titles);
          break;
        }
        case 'forgetPasswordTitle': {
          this.forgetPasswordTitles.next(titles);
          break;
        }
        case 'resetPasswordTitle': {
          this.resetPasswordTitles.next(titles);
          break;
        }
        case 'accountTitle': {
          this.accountTitles.next(titles);
          break;
        }
        case 'accountInformationTitle': {
          this.accountInformationTitles.next(titles);
          break;
        }
        case 'companyAccountInformationTitle': {
          this.companyAccountInformationTitles.next(titles);
          break;
        }
        case 'userRFQTitle': {
          this.userRFQTitles.next(titles);
          break;
        }
        case 'headerTitle': {
          this.headerTitles.next(titles);
          break;
        }
        case 'footerTitle': {
          this.footerTitles.next(titles);
          break;
        }
        case 'searchTitle': {
          this.searchTitles.next(titles);
          break;
        }
        case 'homeTitle': {
          this.homeTitles.next(titles);
          break;
        }
        case 'addressTitle': {
          this.addressTitles.next(titles);
          break;
        }
        case 'serviceTitle': {
          this.serviceTitles.next(titles);
          break;
        }
        case 'dayOfferTitle': {
          this.sliderProductTitles.next(titles);
          break;
        }
        case 'categoriesMenuTitle': {
          this.categoriesMenuTitles.next(titles);
          break;
        }
        case 'aboutUsTitle': {
          this.aboutUsTitles.next(titles);
          break;
        }
        case 'magazineTitle': {
          this.magazineTitles.next(titles);
          break;
        }
        case 'productSearchTitle': {
          this.productSearchTitles.next(titles);
          break;
        }
        case 'apiTitle': {
          this.apiTitles.next(titles);
          break;
        }
        case 'vendorSearchTitle': {
          this.vendorSearchTitles.next(titles);
          break;
        }
        case 'breadcrumbTitle': {
          this.breadcrumbTitles.next(titles);
          break;
        }
        case 'productMainTitle': {
          this.productMainTitles.next(titles);
          break;
        }
        case 'productDetailsTitle': {
          this.productDetailTitles.next(titles);
          break;
        }
        case 'vendorProfileTitle': {
          this.vendorProfileTitles.next(titles);
          break;
        }
        case 'vendorDetailsTitle': {
          this.vendorDetailsTitles.next(titles);
          break;
        }
        case 'rfqTitle': {
          this.rfqTitles.next(titles);
          break;
        }
        case 'publicTitle': {
          this.publicTitles.next(titles);
          break;
        }
        case 'tvListTitle': {
          this.tvListTitles.next(titles);
          break;
        }
        case 'contactUsTitle': {
          this.contactUsTitles.next(titles);
          break;
        }
      }
    });
  }

  getTitles(language: string, section: string): Observable<any> {
    if (language == '' || language == null) return new BehaviorSubject<any>(null);
    const value = JSON.stringify({ SearchKey: section, Language: language });
    return this.httpClient.get<any>(this.baseTitlesUrl, { headers: { value: value } }).pipe(
      map((data: any) => {
        let items: Title[] = data.jsonResult.Data.localestringresource;
        let titles: any = {};
        for (let title of items) {
          let key = title.ResourceName;
          titles[key] = title.ResourceValueOrTitle;
        }
        return titles;
      })
    );
  }

  setLanguage(language: string): void {
    if (this.siteLanguages.find(lang => lang.title === language)) {
      localStorage.setItem('language', language);
      localStorage.setItem('direction', this.siteLanguages.find(lang => lang.title === language)!.direction);
      this.currentLanguage.next(language);
      this.siteDirection.next(this.siteLanguages.find(lang => lang.title === language)!.direction);
    } else {
      this.currentLanguage.next('fa');
    }
  }

  setMoney(money: string): void {
    localStorage.setItem('money', money);
    this.currentMoney.next(money);
  }

  getGallery(position: string): Observable<any> {
    return this.httpClient.get<any>(this.baseGalleryUrl + position.toString());
  }

  getGeoLocation(search: string): Observable<any> {
    return this.httpClient.get(this.baseGeoLocationUrl, {
      headers: { value: encodeURIComponent(search), language: this.language },
    });
  }

  getUnits(): void {
    this.httpClient.get(this.baseUnitsUrl, { headers: { language: this.language } }).subscribe((response: any) => {
      const units = [];
      for (const item of response.jsonResult.Data.unit) {
        const unit: Unit = item;
        unit.Name = item.Name[this.language];
        units.push(unit);
      }
      this.units.next(units);
    });
  }

  sendTicketToFarahosh(data: any): Observable<any> {
    return this.httpClient.post<any>(this.baseRFQUrl, data);
  }

  postContactUs(data: any): Observable<any> {
    return this.httpClient.post(this.baseContactUsUrl, data);
  }

  getFAQ(): Observable<any> {
    return this.httpClient.get(this.baseFAQUrl);
  }

  getIPAddress(): Observable<any> {
    return this.httpClient.get('https://api.ipify.org/?format=json');
  }

  getGeoLocationOfUser(ipAddress: string): Observable<any> {
    let url = 'https://ipapi.co/' + ipAddress + '/json/';
    return this.httpClient.get(url);
  }

  addProductToRFQBasket(product: ProductRFQ): void {
    const products = this.rfqBasketProducts.getValue();
    const index = products.findIndex(p => p.productGUID === product.productGUID);
    if (index === -1) {
      products.push(product);
    }
    this.rfqBasketProducts.next(products);
  }

  removeProductFromRFQBasket(index: number): void {
    const products = this.rfqBasketProducts.getValue();
    products.splice(index, 1);
    this.rfqBasketProducts.next(products);
  }

  addProductToContactSupplier(product: ProductRFQ): void {
    const products = this.contactSupplierProducts.getValue();
    const index = products.findIndex(p => p.productGUID === product.productGUID);
    if (index === -1) {
      products.push(product);
    }
    this.contactSupplierProducts.next(products);
  }

  removeContactSupplierProduct(index: number): void {
    const products = this.rfqBasketProducts.getValue();
    products.splice(index, 1);
    this.rfqBasketProducts.next(products);
  }

  removeAllProductsFromRFQBasket(): void {
    this.rfqBasketProducts.next([]);
  }

  getLanguages(): void {
    this.httpClient.get(this.baseLanguageUrl).subscribe((response: any) => {
      this.siteLanguages = [];
      for (const language of response.jsonResult.Data.language) {
        this.siteLanguages.push({
          direction: language.Rtl ? 'rtl' : 'ltr',
          name: language.Name[language.code],
          title: language.code,
        });
      }
      this.languages.next(this.siteLanguages);
    });
  }


  getIPtoLanguage(): Observable<any>{
    return this.httpClient.get(this.baseIPLanguageUrl);
  }
  getStaticPage(name: string): Observable<any> {
    return this.httpClient.get(this.baseUrlStaticPage, {
      headers: {
        name,
      }
    })
  }
  //##endregion
}
