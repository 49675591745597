<div class="d-flex flex-row border-radius product-box p-2">
  <div class="d-flex justify-content-center p-2">
    <img
      class="vendor-image pointer"
      [src]="vendor.Pictures.length > 2 ? logoPath : defaultProfile"
      [alt]="logo?.Alt ? logo?.Alt : 'avatar'"
      [routerLink]="[
        '/',
        this.language,
        'vendors',
        'detail',
        this.vendor.VendorInformation.VendorGuid,
        this.vendor.VendorInformation.FullName
      ]"
    />
  </div>
  <div class="d-flex flex-column justify-content-between margin px-2 flex-grow-1">
    <h3 class="vendor-title fs-18 bold">
      <a
        (click)="onSelect()"
        [routerLink]="[
          '/',
          this.language,
          'vendors',
          'detail',
          this.vendor.VendorInformation.VendorGuid,
          this.vendor.VendorInformation.FullName
        ]"
        >{{ vendor.VendorInformation.FullName }}</a
      >
    </h3>
    <span class="fs-16 medium-gray"
      >{{ this.vendorProfileTitles.subject }}: {{ this.vendor.VendorInformation.Subject }}</span
    >
    <p class="fs-14">
      {{ this.vendorProfileTitles.short_description }}: {{ this.vendor.VendorInformation.ShortDescription }}
    </p>
  </div>
</div>
