import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { SharedService } from '../shared.service';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import { CategoryService } from '../../category/category.service';
import { TokenStorageService } from '../token.service';
import { AccountService } from '../../account/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Category } from 'src/app/category/category.model';
import { ProductService } from '../../product/product.service';
import { VendorService } from '../../vendor/vendor.service';
import { VendorProfile } from '../../vendor/vendor.model';
import {SubSink} from "subsink";
import {BehaviorSubject} from "rxjs";
import { debounceTime, filter, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  direction = 'rtl';
  headerTitlesBehavioral = this.sharedService.headerTitles;
  searchTitleBehavioral = this.sharedService.searchTitles;
  headerTitles: any;
  apiTitles: any;
  searchTitles: any;
  logo = '';
  searchType: string | null = 'product';
  search = new FormControl('', {
    nonNullable: true,
  });
  language = 'fa';
  isLoggedIn = false;
  fullName = '';
  isQuickLoginOpen: boolean = false;
  isAdmin = false;
  categories: Category[] = [];
  menuTitles: any;
  categoriesMenuTitleBehavioral = this.sharedService.categoriesMenuTitles;
  categoriesMenuTitle: any;
  products = [];
  vendors: VendorProfile[] = [];
  private subs = new SubSink();
  private searchSubject = new BehaviorSubject<string>('');

  constructor(
    private sharedService: SharedService,
    private categoryService: CategoryService,
    private accountService: AccountService,
    private tokenService: TokenStorageService,
    private productService: ProductService,
    private vendorService: VendorService,
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.subs.add(this.accountService.isAuthenticated.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if (this.isLoggedIn) {
        this.fullName = localStorage.getItem('name')!;
      }
    }));
    this.subs.add(this.accountService.isAdmin.subscribe(isAdmin => {
      this.isAdmin = isAdmin;
    }));
    this.subs.add(this.sharedService.apiTitles.subscribe(titles => {
      this.apiTitles = titles;
    }));
    this.subs.add(this.productService.productClearBehavioral.subscribe(isClear => {
      if (isClear) {
        this.search.setValue('');
        this.products = [];
      }
    }));
    this.subs.add(this.vendorService.vendorClear.subscribe(isClear => {
      if (isClear) {
        this.search.setValue('');
        this.vendors = [];
      }
    }));

    const searchValueChanges$ = this.search.valueChanges.pipe(
      debounceTime(300),
      filter(data => data.length > 2),
    );

    this.subs.add(
      searchValueChanges$.pipe(
        switchMap(data => {
          if (this.searchType === 'product') {
            return this.productService.searchProduct(data);
          } else {
            const search = JSON.stringify({
              SearchKey: encodeURIComponent(data),
              Language: this.sharedService.language,
            });
            this.vendorService.getVendors(search);
            return this.vendorService.searchVendors;
          }
        })
      ).subscribe(result => {
        if (this.searchType === 'product') {
          this.products = result;
        } else {
          this.vendors = result;
        }

        const element = document.getElementById('search-result-box');
        if (result.length > 0) {
          this.renderer.removeClass(element, 'invisible');
          this.renderer.addClass(element, 'visible');
        } else {
          this.renderer.removeClass(element, 'visible');
          this.renderer.addClass(element, 'invisible');
        }
      })
    );

    this.search.valueChanges.subscribe(data => this.searchSubject.next(data));

    // this.subs.add(this.search.valueChanges.subscribe(data => {
    //   if (data.length > 2) {
    //     if (this.searchType === 'product') {
    //       this.productService.searchProduct(data).subscribe(products => {
    //         this.products = products;
    //         if (this.products.length > 0) {
    //           const element = document.getElementById('search-result-box');
    //           this.renderer.removeClass(element, 'invisible');
    //           this.renderer.addClass(element, 'visible');
    //         } else {
    //           const element = document.getElementById('search-result-box');
    //           this.renderer.removeClass(element, 'visible');
    //           this.renderer.addClass(element, 'invisible');
    //         }
    //       });
    //     } else {
    //       const search = JSON.stringify({
    //         SearchKey: encodeURIComponent(this.search.value),
    //         Language: this.sharedService.language,
    //       });
    //       this.vendorService.getVendors(search);
    //       this.isVendorCalled = true;
    //
    //       this.vendorService.searchVendors.subscribe(vendors => {
    //         console.log(this.isVendorCalled)
    //         if (this.isVendorCalled) {
    //           this.isVendorCalled = false;
    //           this.vendors = vendors;
    //           console.log(this.vendors)
    //           if (this.vendors.length > 0) {
    //             const element = document.getElementById('search-result-box');
    //             this.renderer.removeClass(element, 'invisible');
    //             this.renderer.addClass(element, 'visible');
    //           } else {
    //             const element = document.getElementById('search-result-box');
    //             this.renderer.removeClass(element, 'visible');
    //             this.renderer.addClass(element, 'invisible');
    //           }
    //         }
    //       });
    //     }
    //   } else {
    //     const element = document.getElementById('search-result-box');
    //     this.renderer.removeClass(element, 'visible');
    //     this.renderer.addClass(element, 'invisible');
    //   }
    // }));
  }

  goToAdmin(): void {
    window.open(
      environment.adminUrl +
        '?token=' +
        encodeURIComponent(localStorage.getItem('token')!) +
        '&name=' +
        localStorage.getItem('name') +
        '&language=' +
        this.sharedService.language,
      '_blank'
    );
  }

  ngOnInit(): void {
    this.subs.add(this.categoryService.categoriesBehavioral.subscribe(categories => {
      this.categories = categories;
    }));
    this.subs.add(this.sharedService.siteDirection.subscribe(direction => {
      this.direction = direction!;
    }));
    this.subs.add(this.sharedService.currentLanguage.subscribe(language => {
      this.language = language;
      this.sharedService.onLanguageChanges(language!, 'headerTitle');
      this.sharedService.onLanguageChanges(language!, 'searchTitle');
    }));
    this.subs.add(this.sharedService.getGallery('2').subscribe((response: any) => {
      if (response.jsonResult.Data.gallery.length > 0) {
        this.logo =
          environment.apiUrl +
          response.jsonResult.Data.gallery.find((logo: { IsThumbnail: boolean }) => !logo.IsThumbnail).Path;
      }
    }));
    this.subs.add(this.searchTitleBehavioral.subscribe(search => {
      this.searchTitles = search;
    }));
    this.subs.add(this.headerTitlesBehavioral.subscribe(header => {
      this.headerTitles = header;
    }));

    this.subs.add(this.sharedService.menuTitles.subscribe(titles => {
      this.menuTitles = titles;
    }));

    this.subs.add(this.categoriesMenuTitleBehavioral.subscribe(titles => {
      this.categoriesMenuTitle = titles;
    }));
    if (localStorage.getItem('searchTypeSelected')) {
      this.searchType = localStorage.getItem('searchTypeSelected');
    } else {
      this.searchType = 'product';
    }
  }

  searchTypeChanged(event: any): void {
    localStorage.setItem('searchTypeSelected' , event.target.value);
    document.getElementById('search-type-dropdown')!.blur();
  }

  logout(): void {
    this.accountService.logout();
  }

  onSearch(): void {
    this.products = [];
    this.vendors = [];
    const element = document.getElementById('search-result-box');
    this.renderer.removeClass(element, 'visible');
    this.renderer.addClass(element, 'invisible');
    if (this.searchType === 'product') {
      this.router
        .navigate([this.language, 'products'], {
          // queryParams: {
          //   ...this.activatedRoute.snapshot.queryParams,
          //   page: 1,
          //   search: this.search.value
          // },
          queryParams: {
            page: 1,
            search: this.search.value
          },
        })
        .then();
    } else {
      this.router
        .navigate([this.language, 'vendors'], {
          queryParams: {
            page: 1,
            search: this.search.value
          },
        })
        .then();
    }
  }
  hide(): void {
    setTimeout(() => {
      this.search.setValue('');
      this.products = [];
      this.vendors = [];
      const element = document.getElementById('search-result-box');
      this.renderer.removeClass(element, 'visible');
      this.renderer.addClass(element, 'invisible');
    }, 500);
  }

  show(): void {
    if (this.products.length > 0 || this.vendors.length > 0) {
      const element = document.getElementById('search-result-box');
      this.renderer.removeClass(element, 'invisible');
      this.renderer.addClass(element, 'visible');
    }
  }

  showQuickView(): void {
    const element = document.getElementById('quick-login');
    this.renderer.addClass(element, 'visible');
    this.renderer.removeClass(element, 'invisible');
  }
  hideQuickView(event: any): void {
    if (event.toElement !== null && event.relatedTarget !== null) {
      const element = document.getElementById('quick-login');
      this.renderer.addClass(element, 'invisible');
      this.renderer.removeClass(element, 'visible');
    }
  }

  hideQuickViewButton(): void {
    const element = document.getElementById('quick-login');
    this.renderer.addClass(element, 'invisible');
    this.renderer.removeClass(element, 'visible');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
