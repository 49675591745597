import Swal from 'sweetalert2';
import {SharedService} from "../shared.service";

export class ErrorHandler {
  apiTitles: any;
  constructor(private sharedService: SharedService) {
    this.sharedService.apiTitles.subscribe(titles => {
      this.apiTitles = titles;
    })
  }
  showError(error: any): void {
    switch (error.status) {
      case 400: {
        let errorMessage = '';
        if (error.error.errs && error.error.errs.length > 0) {
          for (let err of error.error.errs) {
            // errorMessage += `<span>${err.ErrorSource}: ${err.ErrorMessage}</span><br>`;
            errorMessage += `<span>${err.ErrorSource}</span><br>`;
          }
        } else {
          errorMessage = error.error.jsonResult.Message;
        }
        Swal.fire({
          title: this.apiTitles.error,
          html: errorMessage,
          icon: 'error',
          confirmButtonText: this.apiTitles.confirm_button,
        }).then();
        break;
      }
      case 404: {
        Swal.fire({
          title: this.apiTitles.error,
          text: error.error.jsonResult.Message,
          icon: 'error',
          confirmButtonText: this.apiTitles.confirm_button,
        }).then();
        break;
      }
      case 422: {
        let errorMessage = '';
        if (error.error.errs) {
          for (let err of error.error.errs) {
            errorMessage += err.ErrorSource;
          }
        } else {
          errorMessage = error.error.jsonResult.Message;
        }
        Swal.fire({
          title: this.apiTitles.error,
          text: errorMessage,
          icon: 'error',
          confirmButtonText: this.apiTitles.confirm_button,
        }).then();
        break;
      }
      case 500: {
        Swal.fire({
          title: this.apiTitles.error,
          text: this.apiTitles.server_error,
          icon: 'error',
          confirmButtonText: this.apiTitles.confirm_button,
        }).then();
        break;
      }
    }
  }
}
