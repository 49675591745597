import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  visibility = new BehaviorSubject<Boolean>(false);
  notification = {
    title: '',
    description: '',
    icon: '',
    button: '',
    link: '',
  };

  show(title: string, description: string, icon: string, button: string, link: string): void {
    this.notification.title = title;
    this.notification.description = description;
    this.notification.icon = icon;
    this.notification.button = button;
    this.notification.link = link;
    this.visibility.next(true);
  }

  hide(): void {
    this.visibility.next(false);
  }
}
