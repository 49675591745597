import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, Subject } from 'rxjs';
import { Product } from './product.model';
import { SharedService } from '../shared/shared.service';
import { environment } from '../../environments/environment';
import { Brand } from './models/brand.model';
import { ProductSearch } from './product-search.model';
import { ProductAttribute } from './models/attribute.model';
import { Picture } from './models/picture.model';
import { Unit } from '../shared/models/unit.model';
import { ProductPrice } from './models/product-price.model';
import moment from 'jalali-moment';
import { VendorService } from '../vendor/vendor.service';
import {CompareProductModel} from "./models/compare-product.model";

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  units: Unit[] = [];
  loadingProduct = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService,
    private vendorService: VendorService
  ) {
    this.sharedService.units.subscribe(units => {
      this.units = units;
    });
  }

  //##region API Urls
  baseUrlCategoryProducts = '/api/v1/WebProduct/GetCategoryProductList/';
  baseUrlProducts = '/api/v1/WebProduct/GetProductList';
  baseUrlReadyToShipProducts = '/api/v1/WebProduct/GetReadyToShipProductList';
  baseUrlCustomizableProducts = '/api/v1/WebProduct/GetIsCustomizableProductList';
  baseUrlProduct = '/api/v1/WebProduct/GetProductDetails/';
  baseUrlSearchProduct = '/api/v1/WebProduct/SearchProduct';
  baseUrlBrands = '/api/v1/webBrand/GetBrandList';
  baseUrlAdvancedSearchProduct = '/api/v1/WebProduct/WebAdvancedSearchProduct';
  baseUrlProductAttributes = '/api/v1/WebProductAttribute/GetProductAttributes/';
  baseUrlProductPictures = '/api/v1/Picture/GetAllEntityPicturesForProduct/';
  baseUrlProductVendorAndPrice = '/api/v1/WebProductAttribute/GetVendorAndTierPiceList/';
  baseUrlProductFavorites = '/api/v1/WebUserCustomizeInformation';
  baseUrlAllFavorites = '/api/v1/WebUserCustomizeInformation/GetAllRows';
  baseUrlFilters = '/api/v1/WebProduct/GetAllBasicInformation';
  baseUrlCompare = '/api/v1/WebProduct/GetProductsCompare';
  baseUrlSearchCompareProducts = '/api/v1/WebProduct/SearchProductForCompare';
  //##endregion

  //##region Properties
  callUs = {
    fa: 'تماس بگیرید',
    en: 'Call',
    ar: 'مكالمة',
    fr: 'Appel',
  };
  products: Product[] = [];
  productsBehavioral = new Subject<Product[]>();
  productPricesBehavioral = new BehaviorSubject<ProductPrice[]>([]);
  brandsBehavioral = new BehaviorSubject<Brand[]>([]);
  productCertificates = new BehaviorSubject<Picture[]>([]);
  productPackaging = new BehaviorSubject<Picture[]>([]);
  productAttributesBehavioral = new BehaviorSubject<ProductAttribute[]>([]);
  productClearBehavioral = new BehaviorSubject<boolean>(false);
  shouldRefreshFavorites = new BehaviorSubject<boolean>(false);
  shouldAddOrRemoveFavorite = new Subject<string>();
  productCategoryIds = new ReplaySubject<any[]>();
  productBehavioral = new ReplaySubject<any>(1);
  productReadyToShipBehavioral = new ReplaySubject<any>(1);
  productCustomizableBehavioral = new ReplaySubject<any>(1);
  productManagementCertificates = new ReplaySubject<any>(1);
  productProductCertificates = new ReplaySubject<any>(1);
  productVendorLevel = new ReplaySubject<any>(1);
  compareProducts = new BehaviorSubject<CompareProductModel[]>([]);
  //##endregion
  //##region Methods
  getProducts(): void {
    const value = JSON.stringify({
      Language: this.sharedService.language,
    });
    this.httpClient
      .get(this.baseUrlProducts, {
        headers: {
          value: value,
        },
      })
      .subscribe((response: any) => {
        const products: Product[] = [];
        for (let item of response.jsonResult.Data) {
          const product: Product = item;
          product.Detail.currentProductName = product.Detail.ProductName[this.sharedService.language]
            ? product.Detail.ProductName[this.sharedService.language]
            : product.Detail.ProductName['fa'];
          product.Detail.currentProductName = product.Detail.currentProductName ?? product.Detail.ProductName;

          product.Detail.currentBrandName = product.Detail.BrandName[this.sharedService.language]
            ? product.Detail.BrandName[this.sharedService.language]
            : product.Detail.BrandName['fa'];
          product.Detail.currentBrandName = product.Detail.currentBrandName ?? product.Detail.BrandName;

          product.Detail.currentShortDescription = product.Detail.ShortDescription[this.sharedService.language]
            ? product.Detail.ShortDescription[this.sharedService.language]
            : product.Detail.ShortDescription['fa'];
          product.Detail.currentShortDescription =
            product.Detail.currentShortDescription ?? product.Detail.ShortDescription;

          if (item.VendorInformation) {
            product.VendorInformation.FullName = product.VendorInformation.FullName
              ? product.VendorInformation.FullName
              : item.VendorInformation.FullName;
          }

          product.picture.Path = environment.apiUrl + product.picture.Path;

          let minOrder = item.Prices && item.Prices.length > 0 ? item.Prices[0].QuantityFrom : 0;
          for (let price of item.Prices) {
            if (minOrder > price.QuantityFrom) {
              minOrder = price.QuantityFrom;
            }
          }
          product.minOrder = minOrder;
          if (product.minOrder === -1) {
            const language = this.sharedService.language;
            switch (language) {
              case 'fa': {
                product.minOrder = this.callUs.fa;
                break;
              }
              case 'en': {
                product.minOrder = this.callUs.en;
                break;
              }
              case 'ar': {
                product.minOrder = this.callUs.ar;
                break;
              }
              case 'fr': {
                product.minOrder = this.callUs.fr;
                break;
              }
            }
          }
          products.push(product);
        }
        this.productsBehavioral.next(products);
      });
  }

  searchProduct(search: string, category?: string, brand?: string): Observable<any> {
    const value = JSON.stringify({
      PageSize: 10,
      PageNumber: 1,
      Language: this.sharedService.language,
      category: category,
      brand: brand,
      SearchKey: encodeURIComponent(search),
    });
    return this.httpClient.get<any>(this.baseUrlSearchProduct, { headers: { value: value } }).pipe(
      map((data: any) => {
        let items = data.jsonResult.Data.product;
        if (items.length == 0) return items;
        const products: any[] = [];
        for (let item of items) {
          let product: any = item;
          product.rowCount = data.jsonResult.Data.rowCount;
          let temp = undefined;
          try {
            temp = product.Name![this.sharedService.language]
              ? product.Name![this.sharedService.language]
              : product.Name!['fa'];
          } catch (e) {}
          product.Name = temp ?? product.Name;
          try {
            temp = product.VendorInformation.FullName![this.sharedService.language]
              ? product.VendorInformation.FullName![this.sharedService.language]
              : product.VendorInformation.FullName!['fa'];
          } catch (e) {}
          try {
            product.VendorInformation.FullName = temp ?? product.VendorInformation.FullName;
          } catch (e) {}
          try {
            temp = product.VendorInformation.Subject![this.sharedService.language]
              ? product.VendorInformation.Subject![this.sharedService.language]
              : product.VendorInformation.Subject!['fa'];
          } catch (e) {}
          try {
            product.VendorInformation.Subject = temp ?? product.VendorInformation.Subject;
          } catch (e) {}
          try {
            temp = product.VendorInformation.ShortDescription![this.sharedService.language]
              ? product.VendorInformation.ShortDescription![this.sharedService.language]
              : product.VendorInformation.ShortDescription!['fa'];
          } catch (e) {}
          try {
            product.VendorInformation.ShortDescription = temp ?? product.VendorInformation.ShortDescription;
          } catch (e) {}
          let pictures: any[] = product.Pictures;
          product.Pictures = pictures.filter(p => p.IsThumbnail);
          for (let picture of product.Pictures) picture.Path = environment.apiUrl + picture.Path;
          try {
            product.VenodrPicture.Path = environment.apiUrl + product.VenodrPicture.Path;
          } catch (e) {}
          if (product?.ProdcutTierPrices[0]?.QuantityFrom === -1) {
            const language = this.sharedService.language;
            switch (language) {
              case 'fa': {
                product.ProdcutTierPrices[0].QuantityFrom = this.callUs.fa;
                break;
              }
              case 'en': {
                product.ProdcutTierPrices[0].QuantityFrom = this.callUs.en;
                break;
              }
              case 'ar': {
                product.ProdcutTierPrices[0].QuantityFrom = this.callUs.ar;
                break;
              }
              case 'fr': {
                product.ProdcutTierPrices[0].QuantityFrom = this.callUs.fr;
                break;
              }
            }
          }
          products.push(product);
        }
        return products;
      })
    );
  }

  getBrands(): void {
    const value = JSON.stringify({
      PageSize: 100,
      PageNumber: 1,
      Language: this.sharedService.language,
    });
    this.httpClient
      .get(this.baseUrlBrands, {
        headers: {
          value: value,
        },
      })
      .subscribe((response: any) => {
        const brands = [];
        for (const item of response.jsonResult.Data) {
          const brand: Brand = item.Detail;
          brand.picture = environment.apiUrl + item.picture;
          brands.push(brand);
        }
        this.brandsBehavioral.next(brands);
      });
  }

  productAdvancedSearch(filter: any): Observable<any> {
    return this.httpClient
      .get(this.baseUrlAdvancedSearchProduct, {
        headers: {
          value: filter,
        },
      })
      .pipe(
        map((data: any) => {
          let items = data.jsonResult.Data.product;
          if (items.length == 0) return items;
          const products: ProductSearch[] = [];
          for (let item of items) {
            let product: any = item;
            product.rowCount = data.jsonResult.Data.rowcount;
            product.MinimumQuantity = item.ProdcutTierPrices[0].QuantityFrom;
            product.MinimumUnit = item.ProdcutTierPrices[0].UnitName;
            for(const price of item.ProdcutTierPrices) {
              if (price.QuantityFrom < product.MinimumQuantity) {
                product.MinimumQuantity = price.QuantityFrom;
                product.MinimumUnit = price.UnitName;
              }
            }
            let temp = undefined;
            try {
              temp = product.ProductInformation.Name![this.sharedService.language]
                ? product.ProductInformation.Name![this.sharedService.language]
                : product.ProductInformation.Name!['fa'];
            } catch (e) {}
            product.Name = temp ?? product.ProductInformation.Name;
            try {
              temp = product.VendorInformation.FullName![this.sharedService.language]
                ? product.VendorInformation.FullName![this.sharedService.language]
                : product.VendorInformation.FullName!['fa'];
            } catch (e) {}
            try {
              product.VendorInformation.FullName = temp ?? product.VendorInformation.FullName;
            } catch (e) {}
            try {
              temp = product.VendorInformation.Subject![this.sharedService.language]
                ? product.VendorInformation.Subject![this.sharedService.language]
                : product.VendorInformation.Subject!['fa'];
            } catch (e) {}
            try {
              product.VendorInformation.Subject = temp ?? product.VendorInformation.Subject;
            } catch (e) {}
            try {
              temp = product.VendorInformation.ShortDescription![this.sharedService.language]
                ? product.VendorInformation.ShortDescription![this.sharedService.language]
                : product.VendorInformation.ShortDescription!['fa'];
            } catch (e) {}
            try {
              product.VendorInformation.ShortDescription = temp ?? product.VendorInformation.ShortDescription;
            } catch (e) {}
            try {
              temp = product.ProductInformation.ShortDescription![this.sharedService.language]
                ? product.ProductInformation.ShortDescription![this.sharedService.language]
                : product.ProductInformation.ShortDescription!['fa'];
            } catch (e) {}
            try {
              product.ProductInformation.ShortDescription = temp ?? product.ProductInformation.ShortDescription;
            } catch (e) {}
            let pictures: any[] = product.Pictures;
            product.Pictures = pictures.filter(p => p.IsThumbnail);
            for (let picture of product.Pictures) picture.Path = environment.apiUrl + picture.Path;
            try {
              product.VenodrPicture.Path = environment.apiUrl + product.VenodrPicture.Path;
            } catch (e) {}
            if (product.ProdcutTierPrices[0].QuantityFrom === -1) {
              const language = this.sharedService.language;
              switch (language) {
                case 'fa': {
                  product.ProdcutTierPrices[0].QuantityFrom = this.callUs.fa;
                  break;
                }
                case 'en': {
                  product.ProdcutTierPrices[0].QuantityFrom = this.callUs.en;
                  break;
                }
                case 'ar': {
                  product.ProdcutTierPrices[0].QuantityFrom = this.callUs.ar;
                  break;
                }
                case 'fr': {
                  product.ProdcutTierPrices[0].QuantityFrom = this.callUs.fr;
                  break;
                }
              }
            }
            for (const transport of product.ProductTransportation) {
              try {
                transport.Name = transport.Name[this.sharedService.language] ?? transport.Name['fa'];
              } catch (e) {}
            }
            const year = +moment(product.VendorInformation.CreatedOnUtc, 'DD/MM/YYYY HH:MM:SS').format('YYYY');
            if (year) {
              product.Year = new Date().getFullYear() - year + 1;
            } else {
              product.Year = 1;
            }
            products.push(product);
          }
          return products;
        })
      );
  }

  getProductDetail(guid: string): void {
    // this.loadingProduct.next(true);
    this.httpClient.get(this.baseUrlProduct + guid).subscribe((response: any) => {
      let product: any = response.jsonResult.Data;
      product.Detail!.currentProductName = product.Detail!.ProductName[this.sharedService.language]
        ? product.Detail!.ProductName[this.sharedService.language]
        : product.Detail!.ProductName['fa'];
      product.Detail!.currentProductName = product.Detail!.currentProductName ?? product.Detail!.ProductName;
      product.Detail!.currentBrandName = product.Detail!.BrandName[this.sharedService.language]
        ? product.Detail!.BrandName[this.sharedService.language]
        : product.Detail!.BrandName['fa'];
      product.Detail!.currentBrandName = product.Detail!.currentBrandName ?? product.Detail!.BrandName;

      product.Detail!.currentShortDescription = product.Detail!.ShortDescription[this.sharedService.language]
        ? product.Detail!.ShortDescription[this.sharedService.language]
        : product.Detail!.ShortDescription['fa'];
      product.Detail!.currentShortDescription =
        product.Detail!.currentShortDescription ?? product.Detail!.ShortDescription;

      product.Detail!.currentFullDescription = product.Detail!.FullDescription[this.sharedService.language]
        ? product.Detail!.FullDescription[this.sharedService.language]
        : product.Detail!.FullDescription['fa'];
      product.Detail!.currentFullDescription =
        product.Detail!.currentFullDescription ?? product.Detail!.FullDescription;

      for (let cat of product.category!) {
        cat.currentCategory = cat.CatName[this.sharedService.language]
          ? cat.CatName[this.sharedService.language]
          : cat.CatName['fa'];
        cat.currentCategory = cat.currentCategory ?? cat.CatName;
      }

      for (let picture of product.pictures!) {
        picture.Path = environment.apiUrl + picture.Path;
      }

      if (product.Detail.FAQ) {
        for (let faq of product.Detail.FAQ) {
          faq.answer = faq.answer[this.sharedService.language]
            ? faq.answer[this.sharedService.language]
            : faq.answer['fa'];
          faq.question = faq.question[this.sharedService.language]
            ? faq.question[this.sharedService.language]
            : faq.question['fa'];
        }
      } else {
        product.Detail.FAQ = [];
      }

      if (product.ProductCertificates) {
        for (const certificate of product.ProductCertificates) {
          certificate.Path = environment.apiUrl + certificate.Path;
        }
      }
      product.picture = product.pictures!.filter((p: any) => !p.IsThumbnail);

      try {
        product.Detail!.PackagingAndShipping = product.Detail!.PackagingAndShipping.exw
          ? product.Detail!.PackagingAndShipping
          : '';
      } catch (e) {}

      for (let video of product.videos!) {
        video.Path = environment.apiUrl + video.Path;
        video.TumbnailPath = environment.apiUrl + video.TumbnailPath;
      }
      if (product.Detail.ProductionCapacity) {
        for (let capacity of product.Detail.ProductionCapacity) {
          if (this.units.length > 0) {
            if (this.units.find(unit => unit.Id === +capacity.unit)) {
              capacity.unit = this.units.find(unit => unit.Id === +capacity.unit)!.Name;
            }
          }
        }
      }
      if (product.ProductTransportation) {
        for (let transport of product.ProductTransportation) {
          transport.Name = transport.Name[this.sharedService.language]
            ? transport.Name[this.sharedService.language]
            : transport.Name['fa'];
        }
      }
      product.ProductCertificates = product.ProductCertificates.filter((p: any) => !p.IsThumbnail);
      product.VendorPicture.Path = environment.apiUrl + product.VendorPicture.Path;
      this.vendorService.vendorProfile.subscribe(vendor => {
        const year = +moment(vendor.Detail?.CreatedOnUtc, 'DD/MM/YYYY HH:MM:SS').format('YYYY');
        if (year) {
          product.Year = new Date().getFullYear() - year + 1;
        } else {
          product.Year = 1;
        }
      });
      this.productBehavioral.next(product);
      // this.loadingProduct.next(false);
    });
  }

  getProductAttributes(guid: string): void {
    // this.loadingProduct.next(true);
    this.httpClient.get(this.baseUrlProductAttributes + guid).subscribe((response: any) => {
      let productAttributes: ProductAttribute[] = response.jsonResult.Data.product;
      for (const item of productAttributes) {
        for (const element of item.DefaultAttributeValueIds) {
          try {
            element.Name = element.Name[this.sharedService.language] ?? element.Name['fa'];
          } catch {}
        }
      }
      this.productAttributesBehavioral.next(productAttributes);
      // this.loadingProduct.next(false);
    });
  }

  getProductPictures(guid: string, entityName: string): void {
    this.loadingProduct.next(true);
    const GetAllEntityPictures = JSON.stringify({ ProductGuid: guid, EntityName: entityName });
    this.httpClient
      .get(this.baseUrlProductPictures, {
        headers: {
          value: GetAllEntityPictures,
        },
      })
      .subscribe((response: any) => {
        const pictures = [];
        for (let item of response.jsonResult.Data.picture) {
          if (!item.IsThumbnail) {
            const picture: Picture = item;
            picture.Path = environment.apiUrl + picture.Path;
            pictures.push(picture);
          }
        }
        switch (entityName) {
          case 'ProductCertificate': {
            this.productCertificates.next(pictures);
            break;
          }
          case 'ProductPackaging': {
            this.productPackaging.next(pictures);
            break;
          }
        }
      });
    this.loadingProduct.next(false);
  }

  getVendorPriceAndTierPrices(guid: string): void {
    // this.loadingProduct.next(true);
    this.httpClient.get(this.baseUrlProductVendorAndPrice + guid).subscribe((response: any) => {
      let productPrices: ProductPrice[] = response.jsonResult.Data.product;

      for (let price of productPrices) {
        let temp = undefined;
        if (price.IncotermId != null) {
          try {
            temp = price.IncotermName![this.sharedService.language];
          } catch (e) {
            price.IncotermName = temp ?? price.IncotermName;
          }
        } else {
          price.IncotermId = -1;
        }

        if (price.GeolocationId != null) {
          try {
            temp = price.GeolocationName![this.sharedService.language];
          } catch (e) {
            price.GeolocationName = temp ?? price.GeolocationName;
          }
        } else {
          price.GeolocationId = -1;
        }
        try {
          price.currentVendorFullname = price.vendorFullname![this.sharedService.language]
            ? price.vendorFullname![this.sharedService.language]
            : price.vendorFullname!['fa'];
        } catch (e) {}
        price.currentVendorFullname = price.currentVendorFullname ?? price.vendorFullname!;
        try {
          price.currentSpecialAttributeValue = '';
          let temp = undefined;
          for (let attribute of price.SpecialAttributeValue) {
            temp = attribute!.AttributeName[this.sharedService.language]
              ? attribute!.AttributeName[this.sharedService.language]
              : attribute!.AttributeName['fa'];
            price.currentSpecialAttributeValue += temp ?? attribute!.AttributeName;
            price.currentSpecialAttributeValue += ' ( ';
            temp = attribute!.AttributeValue[this.sharedService.language]
              ? attribute!.AttributeValue[this.sharedService.language]
              : attribute!.AttributeValue['fa'];
            price.currentSpecialAttributeValue += temp ?? attribute!.AttributeValue;
            price.currentSpecialAttributeValue += ' ) ';
          }
        } catch (e) {}
      }
      this.productPricesBehavioral.next(productPrices);
    });
    // this.loadingProduct.next(false);
  }

  addProductToFavorite(guid: string, favorite: boolean): Observable<any> {
    const data = {
      usercustomizeinformation: {
        ProductGuid: guid,
        IsInterest: favorite,
      },
    };
    return this.httpClient.post(this.baseUrlProductFavorites, data);
  }

  getFavoriteProducts(): Observable<any> {
    return this.httpClient.get(this.baseUrlAllFavorites);
  }

  getReadyToShipProducts(): void {
    this.httpClient
      .get(this.baseUrlReadyToShipProducts)
      .subscribe((response: any) => {
        const products: Product[] = [];
        for (let item of response.jsonResult.Data) {
          const product: Product = item;
          product.Detail.currentProductName = product.Detail.ProductName[this.sharedService.language]
            ? product.Detail.ProductName[this.sharedService.language]
            : product.Detail.ProductName['fa'];
          product.Detail.currentProductName = product.Detail.currentProductName ?? product.Detail.ProductName;

          product.Detail.currentBrandName = product.Detail.BrandName[this.sharedService.language]
            ? product.Detail.BrandName[this.sharedService.language]
            : product.Detail.BrandName['fa'];
          product.Detail.currentBrandName = product.Detail.currentBrandName ?? product.Detail.BrandName;

          product.Detail.currentShortDescription = product.Detail.ShortDescription[this.sharedService.language]
            ? product.Detail.ShortDescription[this.sharedService.language]
            : product.Detail.ShortDescription['fa'];
          product.Detail.currentShortDescription =
            product.Detail.currentShortDescription ?? product.Detail.ShortDescription;

          if (item.VendorInformation) {
            product.VendorInformation.FullName = product.VendorInformation.FullName
              ? product.VendorInformation.FullName
              : item.VendorInformation.FullName;
          }

          product.picture.Path = environment.apiUrl + product.picture.Path;

          let minOrder = item.Prices && item.Prices.length > 0 ? item.Prices[0].QuantityFrom : 0;
          for (let price of item.Prices) {
            if (minOrder > price.QuantityFrom) {
              minOrder = price.QuantityFrom;
            }
          }
          product.minOrder = minOrder;
          if (product.minOrder === -1) {
            const language = this.sharedService.language;
            switch (language) {
              case 'fa': {
                product.minOrder = this.callUs.fa;
                break;
              }
              case 'en': {
                product.minOrder = this.callUs.en;
                break;
              }
              case 'ar': {
                product.minOrder = this.callUs.ar;
                break;
              }
              case 'fr': {
                product.minOrder = this.callUs.fr;
                break;
              }
            }
          }
          products.push(product);
        }
        this.productReadyToShipBehavioral.next(products);
      });
  }

  getCustomizableProducts(): void {
    this.httpClient
      .get(this.baseUrlCustomizableProducts)
      .subscribe((response: any) => {
        const products: Product[] = [];
        for (let item of response.jsonResult.Data) {
          const product: Product = item;
          product.Detail.currentProductName = product.Detail.ProductName[this.sharedService.language]
            ? product.Detail.ProductName[this.sharedService.language]
            : product.Detail.ProductName['fa'];
          product.Detail.currentProductName = product.Detail.currentProductName ?? product.Detail.ProductName;

          product.Detail.currentBrandName = product.Detail.BrandName[this.sharedService.language]
            ? product.Detail.BrandName[this.sharedService.language]
            : product.Detail.BrandName['fa'];
          product.Detail.currentBrandName = product.Detail.currentBrandName ?? product.Detail.BrandName;

          product.Detail.currentShortDescription = product.Detail.ShortDescription[this.sharedService.language]
            ? product.Detail.ShortDescription[this.sharedService.language]
            : product.Detail.ShortDescription['fa'];
          product.Detail.currentShortDescription =
            product.Detail.currentShortDescription ?? product.Detail.ShortDescription;

          if (item.VendorInformation) {
            product.VendorInformation.FullName = product.VendorInformation.FullName
              ? product.VendorInformation.FullName
              : item.VendorInformation.FullName;
          }

          product.picture.Path = environment.apiUrl + product.picture.Path;

          let minOrder = item.Prices && item.Prices.length > 0 ? item.Prices[0].QuantityFrom : 0;
          for (let price of item.Prices) {
            if (minOrder > price.QuantityFrom) {
              minOrder = price.QuantityFrom;
            }
          }
          product.minOrder = minOrder;
          if (product.minOrder === -1) {
            const language = this.sharedService.language;
            switch (language) {
              case 'fa': {
                product.minOrder = this.callUs.fa;
                break;
              }
              case 'en': {
                product.minOrder = this.callUs.en;
                break;
              }
              case 'ar': {
                product.minOrder = this.callUs.ar;
                break;
              }
              case 'fr': {
                product.minOrder = this.callUs.fr;
                break;
              }
            }
          }
          products.push(product);
        }
        this.productCustomizableBehavioral.next(products);
      });
  }

  getProductSearchFilters(): void {
    this.httpClient.get(this.baseUrlFilters).subscribe((response: any) => {
      this.productManagementCertificates.next(response.jsonResult.Data.ManagementCertificate);
      this.productProductCertificates.next(response.jsonResult.Data.ProductCertificate);
      this.productVendorLevel.next(response.jsonResult.Data.VendorLevel);
    });
  }

  searchCompareProducts(filter: any) {
    return this.httpClient.get(this.baseUrlSearchCompareProducts, {
      headers: {
        value: filter
      }
    });
  }

  getCompareProducts(products: any) {
    const productGuids = JSON.stringify({ productGuids: products });
    this.httpClient.get(this.baseUrlCompare, {
      headers: {
        value : productGuids
      }
    }).subscribe((response: any) => {
      this.compareProducts.next(response.jsonResult.Data);
    });
  }
}
