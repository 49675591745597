import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from './user.mode';
import { environment } from '../../environments/environment';
import { UserRfq } from '../dashboard/user-rfq/user-rfq.model';
import {ActivatedRoute, Router} from '@angular/router';
import { Address } from '../dashboard/user-address/address.model';
import Swal from 'sweetalert2';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  apiTitles: any;
  constructor(private httpClient: HttpClient, private router: Router, private sharedService: SharedService, private activatedRoute: ActivatedRoute) {
    this.sharedService.apiTitles.subscribe(titles => {
      this.apiTitles = titles;
    });
  }
  //#region Api Urls
  private baseUrlLogin = '/api/v1/WebAuthenticate/login';
  private baseUrLogout = '/api/v1/WebAuthenticate/Logout';
  private baseUrlRegister = '/api/v1/WebAuthenticate/UserRegistration';
  private baseUrlUserProfile = '/api/v1/WebAuthenticate/GetUserInformations';
  private baseUrlResendEmail = '/api/v1/WebAuthenticate/ResendEmailConfirmationLink';
  private baseUrlResendEmailWithoutPassword = '/api/v1/WebAuthenticate/ResendEmailConfirmationLinkWithoutPassword';
  private baseUrlConfirmEmail = '/api/v1/WebAuthenticate/ConfirmEmail';
  private baseUrlIsEmailConfirmed = '/api/v1/WebAuthenticate/GetConfirmEmailStatus';
  private baseUrlUpdateUserProfile = '/api/v1/WebAuthenticate/UpdateUserInformations';
  private baseUrlUpdateCompanyProfile = '/api/v1/WebAuthenticate/UpdateUserLegalInformations';
  private baseUrlUserRFQ = '/api/v1/Ticket/GetUserTicketList';
  private baseUrlUserRFQItem = '/api/v1/TicketItem/GetUserTicketMessages';
  private baseUrlUserRFQReply = '/api/v1/TicketItem/TicketReplay';
  private baseUrlProfilePicture = '/api/v1/WebAuthenticate/UpdateUserPicture';
  private baseUrlAddress = '/api/v1/WebAddress/GetUserAddressList';
  private baseUrlAddressById = '/api/v1/WebAddress/GetUserAddress/';
  private baseUrlCreateAddress = '/api/v1/WebAddress';
  private baseUrlUserRoles = '/api/v1/WebAuthenticate/GetUserRoles';
  private baseUrlForgetPassword = '/api/v1/WebAuthenticate/ForgotPassword';
  private baseUrlChangePassword = '/api/v1/WebAuthenticate/ChangePassword';
  private baseUrlUpdatePassword = '/api/v1/WebAuthenticate/UpdateUserPassword';
  private baseUrlActiveSessions = '/api/v1/WebAuthenticate/GetActiveSessions';
  private baseUrlDeActiveSession = '/api/v1/WebAuthenticate/DeactivateSession';
  private baseUrlActiveSessionsWithLogin = '/api/v1/WebAuthenticate/GetActiveSessionsWithLoginInfo';
  private baseUrlDeActiveSessionWithLogin = '/api/v1/WebAuthenticate/DeactivateSessionWithLoginInfo';
  private baseUrlDeActiveAllSessionWithLogin = '/api/v1/WebAuthenticate/DeactivateAllActiveSessionsWithLoginInfo';
  private baseUrlDeActiveAllSession = '/api/v1/WebAuthenticate/DeactivateAllActiveSessions';

  //#endregion

  //#region Properties
  isAuthenticated = new BehaviorSubject(!!localStorage.getItem('token'));
  addressList = new Subject<Address[]>();
  isAdmin = new BehaviorSubject(this.checkAdminStatus());
  isVendor = new BehaviorSubject(this.checkVendorStatus());
  isCustomer = new BehaviorSubject(this.checkCustomerStatus());
  isTax = new BehaviorSubject(this.checkTaxStatus());
  needConfirmEmail = new BehaviorSubject(false);
  userInformation = new BehaviorSubject<User>({
    CompanyEconomicCode: '',
    CompanyName: '',
    CompanyNationalCode: '',
    CompanyPhone: '',
    CompanyRegisterCode: '',
    DisplayOrder: 0,
    Email: '',
    FirstName: '',
    IsThumbnail: false,
    LastName: '',
    MobileNumber: '',
    NationalCode: '',
    Path: '',
    WebSiteAddress: '',
  });
  tickets = new BehaviorSubject<UserRfq>({
    Count: '',
    CreatedOnUtc: '',
    Destination: '',
    Email: '',
    Id: 0,
    IncotermState: '',
    IsVendorReceiver: false,
    Priority: 0,
    ProductGuid: '',
    ProductName: undefined,
    ShippingType: '',
    StatusId: 0,
    Subject: undefined,
    Unit: '',
    VendorGuid: '',
    VendorName: undefined,
    WebSiteAddress: '',
  });
  sessionWasFull = new BehaviorSubject(false);
  //#endregion

  //#region Methods
  login(email: string, password: string, rememberMe: boolean, token: string, shouldNavigate: boolean): void {
    const user = {
      user: {
        Email: email,
        Password: password,
        RememberMe: rememberMe,
        Captcha: token,
      },
    };
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    this.httpClient.post(this.baseUrlLogin, user).subscribe(
      (response: any) => {
        localStorage.setItem('token', response.jsonResult.token);
        localStorage.setItem('name', response.jsonResult.FirstName + ' ' + response.jsonResult.LastName);
        this.isAuthenticated.next(true);
        this.sessionWasFull.next(false);
        this.getUserRoles();
        if (shouldNavigate && (this.activatedRoute.snapshot.queryParams['url'] === undefined)) {
          this.router.navigate(['/']);
        } else if (shouldNavigate && (this.activatedRoute.snapshot.queryParams['url'] !== undefined)) {
          const urls = this.activatedRoute.snapshot.queryParams['url'].split('/');
          this.router.navigate(['/', this.sharedService.language, ...urls]);
        }
      },
      error => {
        if (error.status === 307) {
          localStorage.setItem('email', email);
          this.sessionWasFull.next(true);
        }
        if (error.status === 422) {
          this.needConfirmEmail.next(true);
        }
      }
    );
  }

  register(email: string, password: string, userType: string, token: string): void {
    const user = {
      user: {
        Email: email,
        Password: password,
        RegisterType: userType,
        Captcha: token,
      },
    };
    this.httpClient.post(this.baseUrlRegister, user).subscribe((response: any) => {
      Swal.fire({
        icon: 'success',
        confirmButtonText: this.apiTitles.confirm_button,
        text: response.jsonResult.Message,
      });
      this.router.navigate([this.sharedService.language, 'account', 'login']);
    });
  }

  forgetPassword(Email: string, token: string): Observable<any> {
    return this.httpClient.post<any>(this.baseUrlForgetPassword, null, { headers: { email: Email, Captcha: token } });
  }

  resendConfirmEmail(email: string, password: string): void {
    const value = {
      user: { Email: email, Password: password },
    };
    this.httpClient.post(this.baseUrlResendEmail, value).subscribe((response: any) => {
      Swal.fire({
        icon: 'success',
        confirmButtonText: this.apiTitles.confirm_button,
        text: response.jsonResult.message,
      });
    });
  }

  resendConfirmEmailWithoutPassword(email: string): Observable<any> {
    const body = {};
    return this.httpClient.post(this.baseUrlResendEmailWithoutPassword, body, {
      headers: {
        email: email
      }
    });
  }

  confirmEmail(userGUID: string, code: string): Observable<any> {
    return this.httpClient.post<any>(this.baseUrlConfirmEmail, null, {
      params: {
        userGuid: userGUID,
        code: code,
      },
    });
  }

  changePassword(email: string, code: string, password: string): Observable<any> {
    const user = {
      user: {
        Email: email,
        Code: code,
        Password: password,
      },
    };
    return this.httpClient.post<any>(this.baseUrlChangePassword, user);
  }

  getProfile(): void {
    this.userInformation.next(
      {
        CompanyEconomicCode: '',
        CompanyName: '',
        CompanyNationalCode: '',
        CompanyPhone: '',
        CompanyRegisterCode: '',
        DisplayOrder: 0,
        Email: '',
        FirstName: '',
        IsThumbnail: false,
        LastName: '',
        MobileNumber: '',
        NationalCode: '',
        Path: '',
        WebSiteAddress: '',
      }
    );
    this.httpClient.get<any>(this.baseUrlUserProfile).subscribe((response: any) => {
      const user: User = response.jsonResult.Data.authenticate;
      user.Path = user.Path ? environment.apiUrl + user.Path : '';
      this.userInformation.next(user);
    });
  }

  updateProfile(
    email: string,
    firstName: string,
    lastName: string,
    nCode: string,
    password: string,
    mobile: string,
  ): void {
    let user = {
      user: {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        NationalCode: nCode,
        MobileNumber: mobile,
        Password: password,
      },
    };
    this.httpClient.put<any>(this.baseUrlUpdateUserProfile, user).subscribe(response => {
      this.getProfile();
      Swal.fire({
        icon: 'success',
        confirmButtonText: this.apiTitles.confirm_button,
        text: this.apiTitles.update,
      });
    });
  }

  updatePassword(password: string, newPassword: string): void {
    const user = {
      user: {
        Password: password,
        NewPassword: newPassword,
      },
    };
    this.httpClient.put(this.baseUrlUpdatePassword, user).subscribe((response: any) => {
      Swal.fire({
        icon: 'success',
        confirmButtonText: this.apiTitles.confirm_button,
        text: response.jsonResult.message,
      });
    });
  }

  updateCompanyProfile(
    economicCode: string,
    registerCode: string,
    companyName: any,
    NationalCode: any,
    password: any,
    phoneNumber: any
  ) {
    let company = {
      user: {
        CompanyEconomicCode: economicCode,
        CompanyRegisterCode: registerCode,
        CompanyName: companyName,
        CompanyNationalCode: NationalCode,
        CompanyPhone: phoneNumber,
        Password: password,
      },
    };

    this.httpClient.put<any>(this.baseUrlUpdateCompanyProfile, company).subscribe((response: any) => {
      this.getProfile();
      Swal.fire({
        icon: 'success',
        confirmButtonText: this.apiTitles.confirm_button,
        text: this.apiTitles.update,
      });
    });
  }

  getUserRFQ(page: number): Observable<any> {
    const options = JSON.stringify({ PageSize: 10, PageNumber: page });
    return this.httpClient.get(this.baseUrlUserRFQ, {
      headers: {
        options,
      },
    });
  }

  getUserRFQChat(rfqId: number): Observable<any> {
    const options = JSON.stringify({ PageSize: 1000, PageNumber: 1, TicketId: rfqId });
    return this.httpClient.get(this.baseUrlUserRFQItem, {
      headers: {
        value: options,
      },
    });
  }

  sendTicketReply(ticketId: number, message: string): Observable<any> {
    const value = {
      ticket: { TicketId: ticketId, Message: message },
    };
    return this.httpClient.post<any>(this.baseUrlUserRFQReply, value);
  }

  updateUserProfilePicture(file: File): Observable<any> {
    const formData: FormData = new FormData();
    let JsonValue = {
      picture: {
        MimeType: file.type,
        Seo: 'avatar',
        Alt: 'avatar',
        Description: 'avatar',
        Title: 'avatar',
      },
    };
    formData.append('EntityName', 'User');
    formData.append('EntityId', '0');
    formData.append('PictureProperty.PictureType', '-');
    formData.append('PictureProperty.DisplayOrder', '0');
    formData.append('PictureProperty.file', file);
    formData.append('PictureProperty.JsonValue', JSON.stringify(JsonValue));

    return this.httpClient.put(this.baseUrlProfilePicture, formData);
  }

  checkVendorStatus(): boolean {
    return (localStorage.getItem('userRole') + '').split(',').indexOf('vendor') != -1;
  }

  checkAdminStatus(): boolean {
    return (localStorage.getItem('userRole') + '').split(',').indexOf('admin') != -1;
  }
  checkTaxStatus(): boolean {
    return (localStorage.getItem('userRole') + '').split(',').indexOf('tax') != -1;
  }

  checkCustomerStatus(): boolean {
    return (localStorage.getItem('userRole') + '').split(',').indexOf('customer') != -1;
  }

  getUserRoles(): void {
    this.httpClient.get<any>(this.baseUrlUserRoles).subscribe(result => {
      const roles = result.jsonResult.Role;
      localStorage.setItem('userRole', (roles + '').toLowerCase());
      this.isVendor.next(this.checkVendorStatus());
      this.isAdmin.next(this.checkAdminStatus());
      this.isCustomer.next(this.checkCustomerStatus());
    });
  }

  getAddresses(): Observable<any> {
    return this.httpClient.get(this.baseUrlAddress);
  }

  getAddress(id: number): Observable<any> {
    return this.httpClient.get(this.baseUrlAddressById + id);
  }

  createAddress(address: any): Observable<any> {
    let data = { address: address };
    return this.httpClient.post<any>(this.baseUrlCreateAddress, data);
  }

  updateAddress(address: any, id: number): Observable<any> {
    address.Id = id;
    let data = { address: address };
    return this.httpClient.put<any>(this.baseUrlCreateAddress, data);
  }

  deleteAddress(id: number): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrlCreateAddress + '/' + id);
  }

  logout(): void {
    this.httpClient.post(this.baseUrLogout, {}).subscribe(_ => {
      this.signOut();
    });
  }

  signOut(): void {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('name');
    window.localStorage.removeItem('userRole');
    window.localStorage.clear();
    this.isAuthenticated.next(false);
    this.isAdmin.next(false);
    this.isVendor.next(false);
    this.isCustomer.next(false);
    this.router.navigate(['/']).then();
  }

  isEmailConfirmed(email: string): void {
    this.httpClient
      .get(this.baseUrlIsEmailConfirmed, {
        headers: {
          email,
        },
      })
      .subscribe((response: any) => {
        this.needConfirmEmail.next(!response.jsonResult.ConfirmEmail);
      });
  }

  getActiveSessions() {
    return this.httpClient.get(this.baseUrlActiveSessions);
  }
  deleteActiveSession(sessionId: number) {
    return this.httpClient.post<any>(this.baseUrlDeActiveSession + '/' + sessionId, {});
  }
  deleteAllActiveSessions() {
    return this.httpClient.put<any>(this.baseUrlDeActiveAllSession, {});
  }
  getActiveSessionsWithLogin(email: string, password: string, token: string) {
    const user = {
      user: {Email:email,Password:password,Captcha:token}
    };
    const options = JSON.stringify(user);
    return this.httpClient.get(this.baseUrlActiveSessionsWithLogin, {
      headers: {
        value: options
      }
    });
  }

  deleteActiveSessionWithLogin(sessionId: number, email:string, password: string, token: string) {
    const user = {
      user: {Email:email,Password:password,Captcha:token}
    };
    return this.httpClient.post(this.baseUrlDeActiveSessionWithLogin + '/' + sessionId, user);
  }

  deleteAllActiveSessionsWithLogin(email:string, password: string, token: string) {
    const user = {
      user: {Email:email,Password:password,Captcha:token}
    };
    return this.httpClient.put(this.baseUrlDeActiveAllSessionWithLogin, user);
  }
  //#endregion
}
