<div class="invisible" [ngClass]="rtl === 'rtl' ? 'rtl' : 'ltr'" id="notification">
  <div class="d-flex flex-row shadow-sm border border-radius p-1 notification">
    <img
      src="../../../assets/notification/{{ notificationService.notification.icon }}.svg"
      alt="notification"
      width="48"
      height="48"
    />
    <div class="d-flex flex-column flex-grow-1 m-2">
      <h5 class="title">{{ notificationService.notification.title }}</h5>
      <p class="description">{{ notificationService.notification.description }}</p>
      <a class="btn" [routerLink]="notificationService.notification.link" (click)="notificationService.hide()">{{
        notificationService.notification.button
      }}</a>
    </div>
    <img
      src="../../../assets/notification/exit.svg"
      class="pointer"
      alt="exit"
      width="32"
      height="32"
      (click)="notificationService.hide()"
    />
  </div>
</div>
