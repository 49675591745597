<div class="d-flex flex-row border-radius product-box p-2">
  <div class="d-flex justify-content-center p-2">
    <img
      class="product-image pointer"
      [src]="product.Pictures[0].Path"
      [alt]="product.Pictures[0].Alt"
      [routerLink]="['/', this.language, 'products', 'detail', this.product.ProductGuid, this.product.Name]"
    />
  </div>
  <div class="d-flex flex-column margin px-2 flex-grow-1">
    <h3 class="product-title fs-14">
      <a
        (click)="onSelect()"
        [routerLink]="['/', this.language, 'products', 'detail', this.product.ProductGuid, this.product.Name]"
        >{{ product.Name }}</a
      >
    </h3>
    <span class="product-style mt-2">{{
      sliderProductTitles.min_order +
        ': ' +
        (product.ProdcutTierPrices.length > 0 ? product.ProdcutTierPrices[0].QuantityFrom : 0)
    }}</span>
    <a
      class="product-style mt-2"
      (click)="onSelect()"
      [routerLink]="[
        '/',
        this.language,
        'vendors',
        'detail',
        this.product?.VendorInformation?.VendorGuid,
        this.product?.VendorInformation?.FullName
      ]"
      >{{ sliderProductTitles.vendor + ': '
      }}{{ product.VendorInformation ? product.VendorInformation.FullName : '' }}</a
    >
  </div>
</div>
