import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import {SubSink} from "subsink";
import {SharedService} from "../../shared.service";

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.sass'],
})
export class FormFieldComponent implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() control = new FormControl();
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() maxLength: number = 2500;
  @Input() id: string = 'title';
  @Input() dir = '';
  @Input() pattern: string = '';
  @Input() required = false;
  private subs = new SubSink();
  direction = 'ltr';
  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.subs.add(this.sharedService.siteDirection.subscribe(dir => {
      this.direction = dir;
    }));
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
