<footer class="d-flex flex-column persian-blue white p-3" [dir]="direction"
        [ngClass]="direction === 'rtl' ? 'rtl' : 'ltr'">
  <div class="d-flex flex-row flex-wrap align-items-center">
<!--    <span class="fs-24 bold">OriginPersia</span>-->
    <span class="fs-24 bold">Vtrin</span>
    <span class="flex-grow-1 margin">{{ footerTitle.support }}</span>
  </div>
  <div class="d-flex flex-row flex-wrap my-2 align-items-start justify-content-start">
    <ul class="underline col-12 col-md-6 col-lg-4 col-xxl-3 d-flex flex-column">
      <li class="hide-li">
        <h4 class="fs-14 bold">{{ footerTitle.customer_service }}</h4>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'term']"> {{ footerTitle.rules }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'faqs']"> {{ footerTitle.faq }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a>{{ footerTitle.vtrin_work }}</a>
      </li>
    </ul>
    <ul class="underline col-12 col-md-6 col-lg-4 col-xxl-2 d-flex flex-column">
      <li class="hide-li">
        <h4 class="fs-14 bold">{{ footerTitle.about_vtrin }}</h4>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'aboutUs']"> {{ footerTitle.about_us }}</a>
        <!--        <a [routerLink]="[language, '/']"> {{ footerTitle.about_us }}</a>-->
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'contactUs']"> {{ footerTitle.contact_us }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'jobOffers']">{{ footerTitle.opportunity }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'usage']"> {{ footerTitle.usage }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'privacy']">{{ footerTitle.privacy }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'bugReport']">{{ footerTitle.bug_report }}</a>
      </li>
    </ul>
    <ul class="underline col-12 col-md-6 col-lg-4 col-xxl-2 d-flex flex-column">
      <li class="hide-li">
        <h4 class="fs-14 bold">{{ footerTitle.sell_vtrin }}</h4>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a>{{ footerTitle.product_vendor }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a>{{ footerTitle.servic_vendor }}</a>
      </li>
    </ul>
    <hr class="mx-2 d-none d-lg-flex d-xxl-none w-100"/>
    <ul class="underline col-12 col-md-6 col-lg-4 col-xxl-2 d-flex flex-column">
      <li class="hide-li">
        <h4 class="fs-14 bold">{{ footerTitle.vtrin_service }}</h4>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'consultant']">{{ footerTitle.consultant }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'education']">{{ footerTitle.education }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'originpersiaMag']">{{ footerTitle.vtrin_mag }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'originpersiaTV']">{{ footerTitle.vtrin_tv }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'advertisement']">{{ footerTitle.advertisement }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'packaging']">{{ footerTitle.packaging }}</a>
      </li>
    </ul>
    <ul class="underline col-12 col-md-6 col-lg-4 col-xxl-2 d-flex flex-column">
      <li class="hide-li">
        <h4 class="fs-14 bold">{{ footerTitle.trade }}</h4>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'transport']">{{ footerTitle.transit }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'insurance']">{{ footerTitle.insurance }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'bank-credit']">{{ footerTitle.bank }}</a>
      </li>
      <li class="mt-1 big-margin fs-14">
        <a [routerLink]="[language, 'public', 'customs']">{{ footerTitle.customs }}</a>
      </li>
    </ul>
  </div>
  <hr class="mx-2"/>
  <div class="d-flex flex-column flex-lg-row justify-content-center align-items-center">
    <div class="d-flex align-items-center justify-content-center">
<!--      <div class="mx-2" style="padding: 16px; border-radius: 4px; background-color: white">-->
<!--        <div style="width: 90px; height: 90px;"  >-->
<!--          <img src="../../../assets/images/fstp-logo-125-136.png" width="90" height="90" alt="fstp">-->
<!--        </div>-->
<!--      </div>-->

      <div class="mx-2" style="padding: 16px; border-radius: 4px; background-color: white">
        <div style="width: 90px; height: 90px;"  >
          <img src="../../../assets/images/fstp-logo.png" width="90" height="90" alt="fstp">
        </div>
      </div>

<!--      <a referrerpolicy='origin' class="mx-lg-2" target='_blank' *ngIf="language === 'fa'"-->
<!--         href='https://trustseal.enamad.ir/?id=428645&Code=Y1KgH5OBjNvdoWN5APT088NpglqdqLwD' style="text-decoration: none; padding: 16px; border-radius: 4px; background-color: white">-->
<!--        <div style="width: 90px; height: 90px">-->
<!--          <img referrerpolicy='origin'-->
<!--               src='https://trustseal.enamad.ir/logo.aspx?id=428645&Code=Y1KgH5OBjNvdoWN5APT088NpglqdqLwD'-->
<!--               alt=''-->
<!--               style='cursor:pointer;object-fit: contain;' width="90" height="90"-->
<!--               Code='Y1KgH5OBjNvdoWN5APT088NpglqdqLwD'>-->
<!--        </div>-->
<!--      </a>-->
    </div>
<!--    <div class="d-flex align-items-center justify-content-center mt-3 mt-lg-0">-->
<!--      <a href="https://wetheuae.ae/en" target="_blank" class="mx-lg-2" style="padding: 16px; border-radius: 4px; background-color: white">-->
<!--        <div style="width: 90px; height: 90px;"  >-->
<!--          <img src="../../../assets/images/2031-min.png" width="90" height="90" alt="fstp">-->
<!--        </div>-->
<!--      </a>-->
<!--      <a href="https://service.tccim.ir/" target="_blank" class="mx-2" style="padding: 16px; border-radius: 4px; background-color: white">-->
<!--        <div style="width: 90px; height: 90px;"  >-->
<!--          <img src="../../../assets/images/tehran-min.png" width="90" height="90" alt="fstp">-->
<!--        </div>-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="d-flex align-items-center justify-content-center mt-3 mt-lg-0">-->
<!--      <a class="mx-lg-2" href="https://www.fccima.ir/" target="_blank" style="padding: 16px; border-radius: 4px; background-color: white">-->
<!--        <div style="width: 90px; height: 90px;"  >-->
<!--          <img src="../../../assets/images/fars-min.png" width="90" height="90" alt="fstp">-->
<!--        </div>-->
<!--      </a>-->
<!--    </div>-->
  </div>
  <hr class="mx-2"/>
  <div
    class="d-flex flex-row flex-wrap justify-content-md-between justify-content-center align-items-center copy-right"
  >
    <span class="my-1 text-justify fs-14">{{ footerTitle.copyright }}</span>
    <div class="d-flex flex-row my-1">
      <a>
        <fa-icon [icon]="envelopeIcon" [size]="'2x'"></fa-icon>
      </a>
      <a>
        <fa-icon class="margin" [icon]="linkedInIcon" [size]="'2x'"></fa-icon>
      </a>
      <a>
        <fa-icon class="margin" [icon]="instagramIcon" [size]="'2x'"></fa-icon>
      </a>
      <a>
        <fa-icon class="margin" [icon]="facebookIcon" [size]="'2x'"></fa-icon>
      </a>
    </div>
  </div>
</footer>
