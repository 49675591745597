<div class="px-2 d-flex flex-column position-relative">
  <label *ngIf="title" class="medium-gray fs-16" [for]="id">{{ title }} <span class="red" *ngIf="required" >*</span> </label>
  <input
    [dir]="dir"
    [type]="type"
    [maxlength]="maxLength"
    [formControl]="control"
    class="form-control mt-1"
    autocomplete="off"
    [placeholder]="placeholder"
    [id]="id"
    appBlockCopy
  />
  <img
    alt="eye"
    *ngIf="control.value.length > 0"
    [src]="type === 'password' ? '../../../../assets/icons/eye.svg' : '../../../../assets/icons/eye-hide.svg'"
    class="position-absolute pointer" [ngClass]="{'eye-icon-left': dir === 'rtl', 'eye-icon': dir !== 'rtl'}"
    (click)="changeType()"
  />
</div>
