import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { LanguageComponent } from './language/language.component';
import { MoneyComponent } from './money/money.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LineTitleComponent } from './line-title/line-title.component';
import { RfqFormComponent } from './rfq-form/rfq-form.component';
import { FormFieldComponent } from './ui/form-field/form-field.component';
import { FormAutocompleteComponent } from './ui/form-autocomplete/form-autocomplete.component';
import { FormSelectComponent } from './ui/form-select/form-select.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotificationComponent } from './notification/notification.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormCheckboxComponent } from './ui/form-checkbox/form-checkbox.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormRadioComponent } from './ui/form-radio/form-radio.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SafeHtmlPipe } from './safe-html.pipe';
import { QuickLoginComponent } from './header/quick-login/quick-login.component';
import { AccordionComponent } from './header/accordion/accordion.component';
import { ProductCardSearchComponent } from '../product/product-card-search/product-card-search.component';
import { MobileHeaderComponent } from './header/mobile-header/mobile-header.component';
import { FormTextareaComponent } from './ui/form-textarea/form-textarea.component';
import { VendorCardSearchComponent } from '../vendor/vendor-card-search/vendor-card-search.component';
import { CountryCodeComponent } from './ui/country-code/country-code.component';
import { FormMultiSelectComponent } from './ui/form-multi-select/form-multi-select.component';
import { RfqBasketComponent } from './rfq-basket/rfq-basket.component';
import { FormAutocompleteCountryComponent } from './ui/form-autocomplete-country/form-autocomplete-country.component';
import { FormPasswordFiledComponent } from './ui/form-password-filed/form-password-filed.component';
import {DatepickerComponent} from './ui/datepicker/datepicker.component';
import {NgPersianDatepickerModule} from 'ng-persian-datepicker';
import { BlockCopyDirective } from './directives/block-copy.directive';
import {DateAsAgoPipe} from "./date-as-ago.pipe";
import { NoRightClickDirective } from './directives/no-right-click.directive';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    LanguageComponent,
    MoneyComponent,
    LineTitleComponent,
    RfqFormComponent,
    FormFieldComponent,
    FormCheckboxComponent,
    FormAutocompleteComponent,
    FormSelectComponent,
    NotificationComponent,
    BreadcrumbComponent,
    NotificationComponent,
    BackToTopComponent,
    FormRadioComponent,
    SafeHtmlPipe,
    QuickLoginComponent,
    AccordionComponent,
    ProductCardSearchComponent,
    MobileHeaderComponent,
    FormTextareaComponent,
    VendorCardSearchComponent,
    CountryCodeComponent,
    FormMultiSelectComponent,
    RfqBasketComponent,
    FormAutocompleteCountryComponent,
    FormPasswordFiledComponent,
    DatepickerComponent,
    BlockCopyDirective,
    DateAsAgoPipe,
    NoRightClickDirective,
    NotFoundComponent,
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    LanguageComponent,
    MoneyComponent,
    LineTitleComponent,
    RfqFormComponent,
    NotificationComponent,
    BackToTopComponent,
    BreadcrumbComponent,
    FormFieldComponent,
    FormCheckboxComponent,
    NgxCaptchaModule,
    FormRadioComponent,
    NgxPaginationModule,
    FormAutocompleteComponent,
    SafeHtmlPipe,
    ProductCardSearchComponent,
    FormSelectComponent,
    FormTextareaComponent,
    VendorCardSearchComponent,
    CountryCodeComponent,
    FormMultiSelectComponent,
    AccordionComponent,
    RfqBasketComponent,
    FormAutocompleteCountryComponent,
    FormPasswordFiledComponent,
    DatepickerComponent,
    DateAsAgoPipe,
    NoRightClickDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxCaptchaModule,
    FontAwesomeModule,
    NgxPaginationModule,
    NgPersianDatepickerModule,
  ],
})
export class SharedModule {}
