<div class="position-fixed box d-flex flex-column flex-md-row justify-content-md-between align-items-md-center p-3">
  <div
    class="d-flex flex-row fixed-height pointer"
    #elemt
    (mousedown)="startDragging($event, false, elemt)"
    (mouseup)="stopDragging($event, false)"
    (mouseleave)="stopDragging($event, false)"
    (mousemove)="moveEvent($event, elemt)"
  >
    <div
      *ngFor="let product of products; let i = index"
      class="bg-white box-container border border-radius d-flex flex-row justify-content-between align-items-center p-1 margin"
    >
      <img width="50" height="50" [src]="product.productImage" />
      <h2 class="fs-14 medium-gray margin text-truncate" [title]="product.productName">{{ product.productName }}</h2>
      <button class="btn btn-width" (click)="deleteProductFromBasket(i)">
        <img src="../../../../assets/icons/delete.svg" />
      </button>
    </div>
  </div>
  <div class="d-flex flex-column flex-md-row margin button-container mt-3 mt-md-0">
    <button class="btn btn-primary mb-2 mb-md-0" (click)="goToContactSupplier()">
      {{ rfqTitles.contact_supplier }}
    </button>
    <button class="btn btn-warning mx-md-2" (click)="clearAll()">{{ rfqTitles.clear_all }}</button>
  </div>
</div>
